import { forwardRef, useState, useEffect, useRef, useCallback } from 'react';
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES, MANTEINENCETOKEN, USDC_CONTRACT_ADDRESSES, DEFAULTCOIN, USDT_CONTRACT_ADDRESSES, POLYGONURL} from '../../variaveis';
import resets from '../Transfer/_resets.module.css';
import classes from '../Transfer/Section.module.css';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Web3 from 'web3';
import { Buffer } from "buffer/";
import { BRLAContractAbi, USDCContractAbi, USDTContractAbi } from '../../utils/abis';
import usaFlag from '../Transfer/usa_flag.png';
import brazilFlag from '../Transfer/brazil_flag.png';
import ListaCoins from '../../Components/ListaCoins';
import ListaCoinsRecebe from '../../Components/ListaCoinsRecebe';
import Checked from '../Transfer/checked.png';
import { useContext } from 'react';
import socketContext from '../Websocket/WebSocketContext';
import { useTranslation } from 'react-i18next';
import ListaTransfer from '../ListaTransfer';
import Select from 'react-select';
import ListaChains from '../ListaChains';
import ListaCoinsPayment from '../ListaCoinsPayment';
import { deparaBank } from './deparaBank';
import { isCPFValid, validaCNPJ } from './validaTaxId';
import QRCode from 'qrcode.react';

/* @figmaId 333:2369 */
const Payment = forwardRef((props, ref) => {
  const [message, setMessage] = useState({ text: '', color: '' });
  const { t, i18n} = useTranslation();
  const [quoteResult, setQuoteResult] = useState(null);
  const [quoteResultFinal, setQuoteResultFinal] = useState(null);
  Payment.whyDidYouRender = true;


   
  const stopPropagation = (event) => {
    event.stopPropagation();
};

  const bankOptions = Object.entries(deparaBank).map(([bankName, bankCode]) => ({
    value: bankCode,
    label: bankName
}));

// State para armazenar o banco selecionado
const [selectedBank, setSelectedBank] = useState(null);

const handleChange = (selectedOption) => {
  console.log(selectedOption)
  setSelectedBank(selectedOption);
  // Aqui você pode fazer outras ações, como atualizar um estado ou chamar um callback
  console.log(`Banco selecionado:`, selectedOption);
};
 
const [accountType, setAccountType] = useState('checking');

    // Função para lidar com a mudança de seleção do select
    const handleAccountTypeChange = (event) => {
        setAccountType(event.target.value);
    };


  
  const [users, setUsers] = useState([]);
  const [fees, setFees] = useState([]);
  const [balance, setBalance] = useState([]);
  const [balanceUsd, setBalanceUsd] = useState([]);
  const [balanceUsdt, setBalanceUsdt] = useState([]);
  const [pixKeyType, setPixKeyType] = useState(users?.kyc?.documentType === 'CPF' ? 'CPF' : 'CNPJ');
  const [pixKey, setPixKey] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [fullName, setfullName] = useState('');
  const [transferType, settransferType] = useState({
    flag: '',
    abbreviation: 'pix'
  });
  const [pixKeyValid, setPixKeyValid] = useState(false);
  const [erroreq, seterroreq] = useState(false);
  const [currencyType, setcurrencyType] = useState('');
  const [CpfCnpj, setCpfCnpj] = useState('');
  const [Token, setToken] = useState(null);
  const [SocketMessage, setSocketMessage] = useState([]);
  const [ValidCpfCnpj, setValidCpfCnpj] = useState(false);
  const [marginLeftValue, setMarginLeftValue] = useState('100px');
  const [marginLeftValueP, setMarginLeftValueP] = useState('70px');
  const [codeoneActive, SetcodeoneActive] = useState(false);
  const [codetwoActive, SetcodetwoActive] = useState(false);
  const [codetreeActive, SetcodetreeActive] = useState(false);
  const [codefourActive, SetcodefourActive] = useState(false);
  const [codefiveActive, SetcodefiveActive] = useState(false);
  const [codesixActive, SetcodesixActive] = useState(false);
 

  const handlePixKeyChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setPixKeyValid(false)
    const inputValue = event.target.value;
    let formattedValue = inputValue;
  
    

    setPixKey(inputValue);
  };

  const handleBranchCodeChange = (event) => {
    const inputValue = event.target.value;  
    setBranchCode(inputValue);
  };

  const handleAccountNumberChange = (event) => {
    const inputValue = event.target.value;  
    setAccountNumber(inputValue);
  };

  const handleFullNameChange = (event) => {
    const inputValue = event.target.value;  
    setfullName(inputValue);
  };



  const handleCpfCnpjChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setValidCpfCnpj(false);
    const inputValue = event.target.value;
    let formattedValue = inputValue;
  
    

    setCpfCnpj(inputValue);
  }; 

  function isValidEmailFormat(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);

  }
  
  
  const handlePixKeyFormat = () => {
    let formattedValue = pixKey;
  
    // Check for CPF format (11 digits)
    if (isCPFValid(pixKey) && /^\d{11}$/.test(pixKey)) {
      formattedValue = `${pixKey.substring(0, 3)}.${pixKey.substring(3, 6)}.${pixKey.substring(6, 9)}-${pixKey.substring(9)}`;
      
    }
  
     // Check for CNPJ format (14 digits)
  if (validaCNPJ(pixKey) && /^\d{14}$/.test(pixKey)) {
    formattedValue = `${pixKey.substring(0, 2)}.${pixKey.substring(2, 5)}.${pixKey.substring(5, 8)}/${pixKey.substring(8, 12)}-${pixKey.substring(12)}`;
    
  }

  // Check for Cell Phone format (11 or 12 digits)
  if (/^\d{11,12}$/.test(pixKey) && !isCPFValid(pixKey) && !validaCNPJ(pixKey)) {
    formattedValue = `+55 (${pixKey.substring(0, 2)}) ${pixKey.substring(2, 7)}-${pixKey.substring(7)}`;
    
  }

  if (pixKey.replace(' ','').replace(/-/g, '').length === 32) {
   setPixKeyValid(true);
   setPixKeyType('Chave-aleatoria');
   GetPixInfo(pixKey.replace(' ',''));
   console.log("chave aleatoria")
  }

  if (pixKey.replace('+55','',).replace('(','').replace(')').replace(' ').replace('-','').length === 11) {
    setPixKeyValid(true)
    setPixKeyType('Celular');
    GetPixInfo(pixKey.replace('+55','',).replace('(','').replace(')').replace(' ').replace('-',''))
   }
  
  if (isValidEmailFormat(pixKey.replace(' ',''))) {
    setPixKeyValid(true)
    setPixKeyType('Email');
    GetPixInfo(pixKey.replace(' ',''))
  }


  if (isCPFValid(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''))) {
    setPixKeyValid(true);
    setPixKeyType('CPF');
    setValidCpfCnpj(true);
    GetPixInfo(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));

  }

  if (validaCNPJ(pixKey.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))) {
    setPixKeyValid(true);
    setPixKeyType('CNPJ');
    setValidCpfCnpj(true);
    GetPixInfo(pixKey.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))
  }

    setPixKey(formattedValue);
  };



  const handleCpfCnpjFormat = () => {
    let formattedValue = CpfCnpj;
  
    // Check for CPF format (11 digits)
    if (isCPFValid(CpfCnpj) && /^\d{11}$/.test(CpfCnpj)) {
      formattedValue = `${CpfCnpj.substring(0, 3)}.${CpfCnpj.substring(3, 6)}.${CpfCnpj.substring(6, 9)}-${CpfCnpj.substring(9)}`;
      
    }
  
     // Check for CNPJ format (14 digits)
  if (validaCNPJ(CpfCnpj) && /^\d{14}$/.test(CpfCnpj)) {
    formattedValue = `${CpfCnpj.substring(0, 2)}.${CpfCnpj.substring(2, 5)}.${CpfCnpj.substring(5, 8)}/${CpfCnpj.substring(8, 12)}-${CpfCnpj.substring(12)}`;
    
  }

  if (isCPFValid(CpfCnpj.replace('.','').replace('-','').replace('.','').replace(' ',''))) {
    setValidCpfCnpj(true);
    setCpfCnpj(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));

  }

  if (validaCNPJ(CpfCnpj.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))) {
    setValidCpfCnpj(true);
    setCpfCnpj(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));
  }

    setCpfCnpj(formattedValue);
  };

  const handleWalletChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setWalletValue(event.target.value);
    
  };


  useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      const parsedUsers = JSON.parse(loadedUsers);
    setUsers(parsedUsers);
    setPixKeyType(parsedUsers.kyc.documentType === 'CPF' ? 'CPF' : 'CNPJ');
    }
 
    const fetchBalance = async () => {
    
   
      // Cria uma nova instância do web3 com o Infura
      const web3 = new Web3(new Web3.providers.HttpProvider(POLYGONURL));

      // Mapeia cada carteira para um objeto que inclui o saldo BRLA
      
          const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[137];
          const USDC_CONTRACT_ADDRESS = USDC_CONTRACT_ADDRESSES[137];
          const USDT_CONTRACT_ADDRESS = USDT_CONTRACT_ADDRESSES[137];
          const contract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
          const contractUsd = new web3.eth.Contract(USDCContractAbi, USDC_CONTRACT_ADDRESS);
          const contractUsdt = new web3.eth.Contract(USDTContractAbi, USDT_CONTRACT_ADDRESS);
          const balance = await contract.methods.balanceOf(users?.wallets?.evm).call();
          const balanceUsd = await contractUsd.methods.balanceOf(users?.wallets?.evm).call();
          const balanceUsdt = await contractUsdt.methods.balanceOf(users?.wallets?.evm).call();
          setBalance(balance)
          setBalanceUsdt(balanceUsdt);
          setBalanceUsd(balanceUsd);
      
  };

  if (users?.wallets?.evm) {
    fetchBalance();
  }



  }, [users?.wallets?.evm]);
  
  

  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [priceusd, setPriceusd] = useState('');
  const navigate = useNavigate();



  useEffect(() => {
    // Detectar sistema operacional
    const os = window.navigator.platform;
    const userAgent = window.navigator.userAgent;
    const linuxPattern = /Linux/;
    const windowsPattern = /Win/;
    const firefoxPattern = /Firefox/;
  
    // Se o sistema operacional for Linux ou o navegador for Firefox, defina marginLeft para '30px'
    if ( firefoxPattern.test(userAgent)) {
      setMarginLeftValue('20px');
      setMarginLeftValueP('-10px');
    }

    else if (linuxPattern.test(os)) {

      setMarginLeftValue('60px');
      setMarginLeftValueP('30px');
      
    }
  
    // Se o sistema operacional for Windows, defina marginLeft para '100px'
    else {
      setMarginLeftValue('100px');
      setMarginLeftValueP('70px');
    }
  }, []);
  

 

  const {
    register,
    handleSubmit,
    setFocus,
    control, // Adicione esta linha
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const [mintValue, setMintValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [ishighValue, setIshighvalue] = useState(false);
  const [recebeValue, setRecebeValue] = useState('');
  const [showPopup, setshowPopup]= useState(false);
  const [walletValue, setWalletValue] = useState('');
  const [isInvalidrecebe, setIsInvalidRecebe] = useState(false);

  function debounce(func, delay) {
    let timeout;
    return function(...args) {
      const context = this;
      return new Promise((resolve, reject) => {
        clearTimeout(timeout);
        timeout = setTimeout(async () => {
          try {
            const result = await func.apply(context, args);
            resolve(result);
          } catch (error) {
            reject(error);
          }
        }, delay);
      });
    };
  }

  const debouncedFastQuote = useRef(debounce(async (amount, chain, inputCoin, outputCoin, fixOutput) => {
    const result = await fastQuote(amount, chain, inputCoin, outputCoin, fixOutput);
    setQuoteResult(result);
    return result;
  }, 500));


const [isMintInput, setIsMintInput] = useState(true);

const handleMintInputChange = (e) => {
    let value = e.target.value;
    setIsMintInput(true);
    setMessage('');
 
  setIshighvalue(false);
    // Remove qualquer caractere que não seja número, ponto ou vírgula
    value = value.replace(/[^\d.,]/g, '');
  
    // Adiciona o "R$" apenas para exibição no input
    const displayValue = `${value}`;
  
    // Verifica se o valor é uma string vazia
    if (value === '') {
      setMintValue(displayValue);
     
      setIsInvalid(false);
      return;
    }

    // Remove o "R$" para realizar a validação numérica
    const numericValue = parseFloat(value.replace('R$', '').replace(',', '.'));
  
    // Verifica se o valor é um número com até duas casas decimais
    if (/^\d*\.?\d{0,2}$/.test(numericValue)) {
      setMintValue(displayValue);
      
      debouncedFastQuote.current(parseFloat((numericValue * 100).toFixed(0)), selectedToken.chain, selectedToken.abbreviation, 'BRLA' , false);
       

    }
  };
  

  useEffect(() => {
    if (quoteResult) {
      
        if (quoteResult.amountUsd !== undefined) {
          if (isMintInput) {
            if ((parseFloat(quoteResult.amountBrl)) <0 )  {
              setRecebeValue(0)
            }
            else { setRecebeValue((parseFloat(quoteResult.amountBrl) ).toString());
            }
          } else {
            if ((parseFloat(quoteResult.amountUsd)) <0 )  {
              setMintValue(0) } else {
                setMintValue((parseFloat(quoteResult.amountUsd) ).toString());
              }
            
          }
        }

 
    }
  }, [quoteResult, isMintInput]);




 useEffect(() => {
   
  console.log("completed: ", completed)
  }, [completed]);
  

  const handleRecebeInputChange = (e) => {
    let value = e.target.value;
    setIsMintInput(false);
    setMessage('');
   
    // Remove qualquer caractere que não seja número, ponto ou vírgula
    value = value.replace(/[^\d.,]/g, '');
  
    // Adiciona o "R$" apenas para exibição no input
    const displayValue = `${value}`;
  
    // Verifica se o valor é uma string vazia
    if (value === '') {
      setRecebeValue(displayValue);
      setIsInvalidRecebe(false);
      return;
    }
    // Remove o "R$" para realizar a validação numérica
    const numericValue = parseFloat(value.replace('R$', '').replace(',', '.'));
  
    // Verifica se o valor é um número com até duas casas decimais
    if (/^\d*\.?\d{0,2}$/.test(numericValue)) {
      
      debouncedFastQuote.current(parseFloat((numericValue * 100).toFixed(0)), selectedToken.chain, selectedToken.abbreviation, 'BRLA' , true);
      }
    
      setRecebeValue(displayValue);
   
  };


  useEffect(() => {

    setCompleted(false);
    setMessage('');
    setRecebeValue('');
    setMintValue('');
    

  }, []);

  useEffect(() => {
    setMessage('');
    if (recebeValue === '') {
      setMintValue('');
      setIsInvalid(false)
    } 

    if (isNaN(mintValue)) {
      setIsInvalid(true)
    }
    

    
    if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }

 
    if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }


  }, [recebeValue]);

  
  
  useEffect(() => {
    setMessage('');
    if (mintValue === '') {
     
     setRecebeValue('');
      
      
      setIsInvalid(false)
    } 
    if (isNaN(mintValue) || mintValue ==='') {
      setIsInvalid(true)
    }

    
    if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }


    if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }    
    
    
  }, [mintValue]);
  

  
  const [showListaCoins, setShowListaCoins] = useState(false);
  const [showListaCoinsRecebe, setShowListaCoinsRecebe] = useState(false);
  const [showListaChains, setShowListaChains] = useState(false);
  const [showListaTransfer, setShowListaTransfer] = useState(false);
  const ListaCoinsRef = useRef();
  const ListaTransferRef = useRef();
  const ListaCoinsRecebeRef = useRef();

  const buttonRefListaCoinst = useRef(null);
  const buttonRefListaCoinsRecebet = useRef(null);
  const buttonRefListaTransfer = useRef(null);

  const ListaChainsRef = useRef();
  const buttonRefListaChain = useRef(null);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaCoins && ListaCoinsRef.current && !ListaCoinsRef.current.contains(event.target) && !buttonRefListaCoinst.current.contains(event.target)) {
      setShowListaCoins(false);
    
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaCoins]);



useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaTransfer && ListaTransferRef.current && !ListaTransferRef.current.contains(event.target) && !buttonRefListaTransfer.current.contains(event.target)) {
      setShowListaTransfer(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaTransfer]);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaChains && ListaChainsRef.current && !ListaChainsRef.current.contains(event.target) && !buttonRefListaChain.current.contains(event.target)) {
      setShowListaChains(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaChains]);


const [selectedToken, setSelectedToken] = useState({
  flag: 'currency-flag-polygon',
  abbreviation: 'USDCNative',
  chain:'Polygon',
  name:'USDC'
});


const [selectedTokenRecebe, setSelectedTokenRecebe] = useState({
  flag: 'currency-flag-brl',
  abbreviation: 'BRL'
});

const [selectedChain, setSelectedChain] = useState({
  flag: 'currency-flag-polygon',
  abbreviation: 'Polygon'
});

const handleTokenSelect = (token) => {
  setMintValue('');
  setRecebeValue('');
  setQuoteResult('');
  setSelectedChain({
    flag: 'currency-flag-polygon',
    abbreviation: 'Polygon'
  })
  setSelectedToken(token);
  setShowListaCoins(false); // Close the dropdown after selection
  if (token.abbreviation === 'USD') {
    
  }

  
  
};


const handleListaSelect = (type) => {
  setMintValue('');
  setRecebeValue('');
  setShowListaTransfer(false);
  settransferType(type);

};



const handleTokenRecebeSelect = (token) => {
  setSelectedTokenRecebe(token);
  setQuoteResult('');
  setSelectedChain({
    flag: 'currency-flag-polygon',
    abbreviation: 'Polygon'
  })
  
  
  setShowListaCoinsRecebe(false); // Close the dropdown after selection
};


const handleChainSelect = (chain) => {
  setSelectedChain(chain);
  setQuoteResult('');
  console.log("chain: ", chain)

 
  setShowListaChains(false); // Close the dropdown after selection
};

const currencyFlagClass = `currency-flag ${selectedToken.flag}`;
const currencyFlagRecebeClass = `currency-flag ${selectedTokenRecebe.flag}`;
const currencyFlagPIXClass = `currency-flag currency-flag-pix`;
const currencyFlagPolygonClass = selectedChain.abbreviation === 'Polygon' ?  `currency-flag currency-flag-polygon` : selectedChain.abbreviation === 'XRP' ? `currency-flag currency-flag-xrp` : selectedChain.abbreviation === 'Tron' ? `currency-flag currency-flag-tron` :  selectedChain.abbreviation === 'CELO' ? `currency-flag currency-flag-celo` : `currency-flag currency-flag-moonbeam`   ;

 // Adicione um estado para salvar o result e gerenciar o cronômetro
 const [swapResult, setSwapResult] = useState(null);
 const [timer, setTimer] = useState(300); // 5 minutos em segundos

 // Função que controla o cronômetro
 useEffect(() => {
   if (swapResult && timer > 0) {
     const countdown = setInterval(() => {
       setTimer((prev) => prev - 1);
     }, 1000);

     return () => clearInterval(countdown);
   }

   if (timer === 0) {
     resetPaymentState(); // Voltar para o estado inicial
   }
 }, [swapResult, timer]);

 const resetPaymentState = () => {
   setSwapResult(null);
   setTimer(300);
   setMintValue('');
   setRecebeValue('');
   setPixKey('');
   setPixKeyValid(false);
   setMessage({ text: '', color: '' });
 };


const onSubmit = async (data) => {
  setIsLoading(true); // Definir como loading quando a requisição começar
  console.log("CARALHOOOOOOOO")


  const cleanedPixKey = pixKeyType === 'CPF' || pixKeyType === 'CNPJ' || pixKeyType === 'Celular' ? pixKey.replace(/[-.()]/g, '').replace('R$ ','').replace('+55').replace('/').replace(' ') : pixKey;
   
  setIsLoading(true)

  let amount = 0
  let fixOutput = false
  if (isMintInput) {
   amount = parseFloat((parseFloat(mintValue)*100).toFixed(0))
   fixOutput = false
  } else {
   amount = parseFloat((parseFloat(recebeValue)*100).toFixed(0))
   fixOutput = true
  }
  const quoteResult = await debouncedFastQuote.current(amount, selectedChain.abbreviation, selectedToken.abbreviation, 'BRLA', fixOutput);
  console.log("quoteResult: ", quoteResult)
  setQuoteResultFinal(quoteResult)
  const otp = data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6
  const result = await GenerateSwap(quoteResult?.token, cleanedPixKey, pixKeyType==='CPF' || pixKeyType==='CNPJ' ? pixKey :  CpfCnpj,fullName,selectedBank?.value,branchCode,accountNumber, otp);
  if (!result.error) {
    setSwapResult(result);
  } else {
   setMessage({text: result.error, color: 'red'}) 
  }
  


setIsLoading(false); // Definir como não loading quando a requisição terminar



}

const getClass = () => {
 
   if (selectedTokenRecebe.abbreviation === 'BRL' && selectedToken.abbreviation === 'BRL' && !pixKeyValid) {
    return 'brl-brl-payment';
    
  } else if (selectedTokenRecebe.abbreviation === DEFAULTCOIN && selectedToken.abbreviation === 'USD') {
    return 'brl-brl-payment'
  }
  else if (selectedTokenRecebe.abbreviation === 'BRL' && selectedToken.abbreviation === 'USD' && pixKeyValid) {
    return 'brl-usd-pix-payment'
      }
   else {
    return 'brl-usd-payment';
  }

}
const [pixInfo, setPixInfo] = useState(null);

async function GetPixInfo(pixKeyinput) {
  const response = await fetch(`${ENDPOINT}/pay-out/pix-info?pixKey=${pixKeyinput}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "MAINTENANCE-TOKEN":MANTEINENCETOKEN,
    },
    credentials:'include',
  });
  setIsLoading(false); // Definir como não loading quando a requisição terminar

  if (response.status === 200 || response.status === 201) {
    const responseData = await response.json();
    setPixInfo(responseData);

    
    // Não reabilite o botão
  } else {
    let responseData = {};
    if (response.headers.get('content-type').includes('application/json')) {
      responseData = await response.json();
    }
    // Trate erros aqui, caso necessário
   setMessage({text: responseData.error, color: 'red'})

}
}

function toTitleCase(str) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}



async function fastQuote(amount, chain, inputCoin, outputCoin, fixOutput) {
  try {
    setIsLoading(true)
    const resFastQuote = await fetch(`${ENDPOINT}/fast-quote?operation=pix-to-usd&amount=${amount}&chain=${chain}&inputCoin=${inputCoin}&outputCoin=${outputCoin}&fixOutput=${fixOutput}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        "MAINTENANCE-TOKEN": MANTEINENCETOKEN
      },
    });
  
    if (resFastQuote.status === 401) {
      navigate('/login'); 
      return;
    }
    if (!resFastQuote.ok) { // Trata qualquer status diferente de 200-299
      console.log("PQP"); // Agora será exibido para qualquer erro HTTP
      setIsLoading(false)
      throw new Error(`HTTP error! Status: ${resFastQuote.status}`);
    }

    const data = await resFastQuote.json(); 
    setIsLoading(false)
    return data;
  } catch (error) {
    console.log("PQP")
      setMintValue('')
      setRecebeValue('')
      console.error('Error:', error.response?.data || error.message);
      setIsLoading(false)
      throw error;
  }

  
}
  
  
  
  async function GenerateSwap(token, pixKey,taxId,name,ispb,branchCode,accountNumber, otp) {
    try {
    const resFastQuote = await fetch(`${ENDPOINT}/pay-out/usd-to-pix/payment?otp=${otp}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        "MAINTENANCE-TOKEN": MANTEINENCETOKEN
      },
      body: JSON.stringify({
        token: token,
        pixKey: pixKey,
        taxId: taxId,
        name: name,
        ispb: ispb,
        branchCode: branchCode,
        accountNumber: accountNumber

      }),
    });
  
    if (resFastQuote.status === 401) {
      navigate('/login'); 
      return;
    }
    const data = await resFastQuote.json(); 
    return data;
  } catch (error) {
    if (selectedChain.abbreviation==='Tron'&&selectedToken.abbreviation==='USDT'&&selectedTokenRecebe.abbreviation==='USDT') {
      setMintValue('')
      setRecebeValue('')
      setMessage({text: error.response?.data || error.message, color: 'red'})
    }
      console.error('Error:', error.response?.data || error.message);
      throw error;
  }
  }


  useEffect(() => {
  let interval;

  // Função para chamar fastQuote
  const updateFastQuote = async () => {
    if ((mintValue || recebeValue) && selectedToken && selectedTokenRecebe) {
      try {
        const fixOutput = !isMintInput; // Determina se está fixando o output
        const amount = fixOutput ? parseFloat((parseFloat( recebeValue) * 100).toFixed(0)) : parseFloat((parseFloat( mintValue) * 100).toFixed(0)) ;
        const result = await fastQuote(
          amount,
          selectedChain.abbreviation,
          selectedToken.abbreviation,
          'BRLA',
          fixOutput
        );
        setQuoteResult(result); // Atualiza o estado com os dados mais recentes
      } catch (error) {
        setMintValue('')
        setRecebeValue('')
        console.error("Erro ao atualizar fastQuote:", error);
      }
    }
  };

  if (mintValue || recebeValue) {
    // Inicia o intervalo de 5 segundos
    interval = setInterval(() => {
      updateFastQuote();
    }, 5000);
  }

  // Limpa o intervalo ao desmontar ou quando a condição deixa de ser verdadeira
  return () => {
    if (interval) clearInterval(interval);
  };
}, [mintValue, recebeValue, selectedToken, selectedTokenRecebe, selectedChain]);


useEffect(() => {
  let interval; // Variável para o intervalo
  const paymentOrderId = swapResult?.id; // ID salvo da paymentOrder
  console.log("paymentOrderId: ", paymentOrderId)

  const fetchPaymentStatus = async () => {
    try {
      const response = await fetch(`${ENDPOINT}/pay-out/usd-to-pix/payment/history?id=${paymentOrderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "MAINTENANCE-TOKEN": MANTEINENCETOKEN,
        },
        credentials: 'include',
      });

      if (!response.ok) {
        console.error("Erro ao verificar status do pagamento:", response.status);
        return;
      }

      const data = await response.json();
      console.log("data: ", data.paymentUsdToPixLogs[0].status)
      
      // Verifica o status da resposta
      if (data.paymentUsdToPixLogs[0].status === 'PAID') {
        console.log("bora caceta")
        setCompleted(true); // Atualiza para o estado de sucesso
        clearInterval(interval); // Para o intervalo
      }
    } catch (error) {
      console.error("Erro ao verificar status do pagamento:", error);
    }
  };

  if (swapResult && paymentOrderId && !completed) {
    // Configura o intervalo para verificar o status a cada 5 segundos
    interval = setInterval(fetchPaymentStatus, 5000);
  }

  // Cleanup: limpa o intervalo ao desmontar ou quando a transação é concluída
  return () => {
    if (interval) clearInterval(interval);
  };
}, [swapResult, completed]); // Dependências incluem swapResult e completed


  return (
    <>
    {showPopup  && (
 <div className="wallet-popup-overlay" style={{zIndex:5000}}>
 <div className="wallet-popup" style={{position: 'relative', zIndex:20000}}>
   




                 

 </div>
</div>
)}





    <form style={{zIndex:3000}} onSubmit={handleSubmit(onSubmit)}>
         <div style={{zIndex:700}} className="np-theme-personal" ref={ref}>

       
          <section className={`tw-flow__main ${getClass()}`} style={{ position: "absolute",width:'500px',  background:'white', padding:'20px', border:'1px solid white', borderRadius:'15px' }}>
          {swapResult && !completed ? (
    // QR Code Component
    <div
    className="qr-code-screen"
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "40px",
      backgroundColor: "#fff",
      borderRadius: "15px",
      textAlign: "center",
      gap: "20px",
    }}
  >
    {/* Cronômetro */}
    <p
      style={{
        fontSize: "24px",
        fontWeight: "bold",
        color: "#000",
        marginBottom: "20px",
      }}
    >
      {t("time_remaining")}: {Math.floor(timer / 60)}:
      {String(timer % 60).padStart(2, "0")}
    </p>
  
    {/* Título */}
    <h2 style={{ fontSize: "22px", margin: "0 0 10px 0" }}>
      Wallet QR Code
    </h2>
  
    {/* QR Code */}
    <QRCode
      value={users?.wallets?.evm}
      size={200}
      bgColor="transparent"
      fgColor="#000000"
      renderAs="svg"
      imageSettings={{
        src: "https://brla.digital/static/media/logo_b.png",
        x: null,
        y: null,
        height: 24,
        width: 24,
        excavate: true,
      }}
    />
  
    {/* Instruções */}
    <p style={{ fontSize: "16px", margin: "1px 0" }}>
      Wallet: {users?.wallets?.evm}
    </p>

    <p style={{ fontSize: "16px", margin: "1px 0" }}>
      Token: {selectedToken.abbreviation}
    </p>
  
    {/* ID da Transação */}
    <p style={{ fontSize: "16px", margin: "1px 0" }}>
      {t("usd_amount")}: {quoteResultFinal.amountUsd}
    </p>
  
    {/* Valor Total */}
    <p style={{ fontSize: "16px", margin: "1px 0" }}>
      {t("brl_amount")}: {quoteResultFinal.amountBrl}
    </p>
  
    {/* Botão */}
    <button
      onClick={resetPaymentState}
      style={{
        backgroundColor: "#00dc84",
        color: "#fff",
        border: "none",
        borderRadius: "8px",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: "pointer",
      }}
    >
      {t("cancel")}
    </button>
  </div>
  
  ) : (
    <>
          {!completed ? (
      
               
                    <div  className="row mx-auto">
                      <div  className="">
                        <div className="tabs">
                  
                          <div
                            className="tabs__panel-container"
                            style={{ overflow: "visible" }}
                          >
                            <div
                              className="tabs__slider"
                              style={{
                                width: "100%",
                                transform: "translateX(0px)"
                              }}
                            >
                              <div style={{ width: 32, display: "none" }} />
                              <div
                                className="tabs__panel"
                                role="tabpanel"
                                id="calculator-tabs-panel-0"
                                aria-labelledby="calculator-tabs-tab-0"
                                style={{ width: "100%", display: "block" }}
                              >
                                <div
                                  data-tracking-id="calculator"
                                  data-testid="calculator"
                                  className="tw-claculator"
                                >
                                  <div className="" style={{width:'450px'}}>
                                    <label htmlFor="tw-calculator-source">
                                    {t('voce_envia_exatamente')}
                                    </label>
                                    <div  className="tw-money-input form-control" style={{display:'flex', height:'50px', border:'1px solid black', borderRadius:'15px'}}>
                                      <div style={{display:'flex'}}>
                                      <input
                                        className="input-transfer"
                                        id="tw-calculator-source"
                                        inputMode="decimal"
                                        placeholder="1000.00"
                                        autoComplete="off"
                                        style={{border:'none'}}
                                        onChange={handleMintInputChange}
                                        value={
                                          isNaN(mintValue) ? "Carregando..." : mintValue
                                        }

                                        
                                        
                                      />
                                      <div  className="input-group-btn amount-currency-select-btn">
                                        <div style={{width:'100px', marginLeft:marginLeftValue}} className="np-select btn-block btn-group">
                                         <button
                                         style={{minHeight:'70px', height:'70px'}}
                                        
                                         
                                         
  ref={buttonRefListaCoinst}
  className={`btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy`}
  id="tw-calculator-source-select"
  
  role="combobox"
  aria-controls="tw-calculator-source-select-listbox"
  aria-expanded="false"
  aria-autocomplete="none"
  type="button"
  onClick={() => {
    setMintValue('');
    setRecebeValue('');
    setShowListaCoins(!showListaCoins);
  }}
>
                                    
                                            <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                              <div  className="d-flex flex-column justify-content-center">
                                              <i style={{marginLeft: selectedToken.abbreviation === 'USDT' ? '-25px' : '-32px', width:'15px', height:'15px'}} className={currencyFlagClass} />
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                  <span style={{fontSize:'18px', marginRight:'25px', marginLeft:'-25px'}} className="np-text-title-subsection tw-money-input__text">
                                                  {selectedToken.abbreviation==='USD' ? 'USDC' : selectedToken.name}
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                              style={{marginBottom:'40px'}}
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                                className={`${showListaCoins ? 'rotate-icon' : ''}`}
                                                
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                            
                                          </button>
                                          
                                        </div>
                                        
                                      </div>
                                      </div>
                                      {showListaCoins && <ListaCoinsPayment ref={ListaCoinsRef} onTokenSelect={handleTokenSelect} top='80px' left='200px'  />}
 {showListaTransfer && <ListaTransfer ref={ListaTransferRef} onListSelect={handleListaSelect} top='80px' left='200px'  />}

                                      <div
  style={{
    position: 'absolute',
    top: selectedToken.abbreviation==='BRL' && selectedTokenRecebe.abbreviation===DEFAULTCOIN ? '250px' : selectedTokenRecebe.abbreviation !== selectedToken.abbreviation &&
      !(selectedTokenRecebe.abbreviation === DEFAULTCOIN && selectedToken.abbreviation === 'USD')
      ? '250px'   
      : '180px',
    left: '200px',
    zIndex:2000
  }}
>
  {showListaCoinsRecebe && <ListaCoinsRecebe ref={ListaCoinsRecebeRef} onTokenRecebeSelect={handleTokenRecebeSelect} />}
  {showListaChains && <ListaChains ref={ListaChainsRef} onChainSelect={handleChainSelect} token = {selectedToken.abbreviation} tokenRecebe = {selectedTokenRecebe.abbreviation} />}
</div>




                                      
                                    </div>
                                    
                                  </div>
                                  {(selectedTokenRecebe.abbreviation !== selectedToken.abbreviation ) && !(selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='USD' ) && !(selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL' ) && !(selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD') && !(selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USDT')  ? (
                                  <ul className="tw-calculator-breakdown " style={{marginBottom:'-20px'}}>
                                    < li style={{width:'450px', marginTop:'-15px'}}>
                                      <span className="tw-calculator-breakdown__icon">
                                        <span>
                                          <span>–</span>
                                        </span>
                                      </span>
                                      <div  className="tw-calculator-breakdown__content">
                                        <span style={{ fontSize:"14px"}}  className=" tw-calculator-breakdown-item__value">
                                        {
  isNaN(quoteResult?.baseFee) || !mintValue || !recebeValue ? (
    t('carregando')+"..."
  ) : (
    
    parseFloat(quoteResult?.baseFee).toFixed(2) +  ' BRL'
  )
}
                                        </span>
                                        <span className="tw-calculator-breakdown-item__right tw-calculator-breakdown-item__label">
                                          <span data-tracking-id="calculator-payment-select">
                                            <div className="np-select btn-group">
                                              <button
                                                className="btn btn-sm np-btn np-btn-sm np-dropdown-toggle np-text-body-large"
                                                id="np-select-443252"
                                                role="combobox"
                                                aria-controls="np-select-443252-listbox"
                                                aria-expanded="false"
                                                aria-autocomplete="none"
                                                type="button"
                                              >
                                                <span style={{ fontSize:"14px"}} className="np-text-body-large text-ellipsis">
                                                {t('tarifa_total_de_custo')}
                                                </span>
                                                <span
                                                  className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                                  aria-hidden="true"
                                                  role="presentation"
                                                  data-testid="chevron-up-icon"
                                                >
                                                                                             </span>
                                              </button>
                                            </div>
                                          </span>
                                        </span>
                                      </div>
                                    </li>
                                
             
                                    < li style={{width:'450px', marginTop:'-10px'}}>
                                      <span className="tw-calculator-breakdown__icon">
                                        <span>
                                          <span>÷</span>
                                        </span>
                                      </span>
                                      <div  className="tw-calculator-breakdown__content">
                                        <span  className="tw-calculator-breakdown-item__left tw-calculator-breakdown-item__value">
                                          <a
                                            role="button"
                                            className="btn-unstyled"
                                            tabIndex={0}
                                          >
                                            <span style={{ fontSize:"14px"}} className="tw-calculator-breakdown-rate__value">
                                            {
 isNaN(quoteResult?.basePrice) || !mintValue || !recebeValue ? (
  t('carregando')+"..."
) : (
  
  parseFloat(quoteResult?.basePrice).toFixed(2) +  ' BRL'
)
}
                                            </span>
                                          </a>
                                        </span>
                                        <span className="tw-calculator-breakdown-item__right tw-calculator-breakdown-item__label">
                                          <span className="np-popover">
                                            <span className="d-inline-block">
                                              <button
                                                className="btn btn-md np-btn np-btn-md btn-accent btn-priority-3 btn-unstyled p-a-0 tw-calculator-rate-text"
                                                type="button"
                                                style={{
                                                  height: "auto",
                                                  verticalAlign: "bottom"
                                                }}
                                              >
                                                <span style={{ fontSize:"14px"}} data-tracking-id="calculator-fixed-rate-tooltip">
                                                {t('taxa_de_cambio_comercial')}
                                                </span>{" "}
                                                <span
                                                  style={{ whiteSpace: "nowrap" }}
                                                >
                                                 
                                                </span>
                                              </button>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </li>
                                  </ul> ) :(<div></div>)}
                                  <div className="" style={{width:'450px', marginTop:'0px'}}>
                                    <label htmlFor="tw-calculator-target">
                                    {t('beneficiario_recebe')}{" "}
                                    </label>
                                    <div  className="tw-money-input form-control" style={{display:'flex', height:'50px', border:'1px solid black', borderRadius:'15px'}}>
                                      <input
                                        className="input-transfer"
                                        id="tw-calculator-target"
                                        inputMode="decimal"
                                        placeholder={
                                        
                                             '1000.00'
                                            
                                        }
                                        
                                      
                                        autoComplete="off"
                                        onChange={handleRecebeInputChange}
                                        value={isNaN(recebeValue) || recebeValue===''  ? "Carregando..." : recebeValue}

                         
                                        
                                      />
                                      <div className="input-group-btn amount-currency-select-btn">
                                      <div style={{width:'100px', marginLeft:marginLeftValue}} className="np-select btn-block btn-group">
                                          <button
                                          style={{minHeight:'70px', height:'70px'}}
                                           ref={buttonRefListaCoinsRecebet}
                                            className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                            id="tw-calculator-target-select"
                                            role="combobox"
                                            aria-controls="tw-calculator-target-select-listbox"
                                            aria-expanded="false"
                                            aria-autocomplete="none"
                                            type="button"
                                            onClick={() => {
                                             
                                              }}
                                          >
                                            <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                              <div className="d-flex flex-column justify-content-center">
                                              <i style={{marginLeft:selectedTokenRecebe.abbreviation === 'USDT' || selectedTokenRecebe.abbreviation === 'BRLA' || selectedTokenRecebe.abbreviation === 'USDC' ? '-30px' : '-5px'}} className={currencyFlagRecebeClass} />
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                <span style={{fontSize:'18px', marginRight:'25px',
                                                marginLeft:  selectedTokenRecebe.abbreviation === 'USDT' || selectedTokenRecebe.abbreviation === 'BRLA'   ? '-10px' :  selectedTokenRecebe.abbreviation === 'USDC' ? -'15px' : '-7px',}} className="np-text-title-subsection tw-money-input__text">
                                                  {selectedTokenRecebe.abbreviation}
                                                  </span>
                                                </span>
                                         
                                              </div>
                              
                                            </div>
                                                       
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {(selectedTokenRecebe.abbreviation === 'BRL' ) ? (

                                    <>
                          { transferType.abbreviation==='pix' ?
                                  <div style={{marginBottom:"0px"}} className="">
                                    <label htmlFor="tw-calculator-target">
                                    {t('chave_pix')}{" "}
                                    </label>
                                    <div  className="tw-money-input form-control" style={{display:'flex', height:'50px', border:'1px solid black', borderRadius:'15px', width:"450px"}}>
                                      <input
                                        className="input-transfer"
                                        id="tw-calculator-target"
                                        inputMode="text"
                                        placeholder={t('digite_sua_chave_pix')}
                                        autoComplete="off"
                                        onChange={handlePixKeyChange}
                                        onBlur={handlePixKeyFormat}
                                        value={pixKey}
                                        style={{width:"300px"}}
                                        
                                        
                                      />
                                      <div className="input-group-btn amount-currency-select-btn">
                                      <div style={{width:'100px', marginLeft:'16px'}} className="np-select btn-block btn-group">
                                          <button
                                           style={{minHeight:'70px', height:'70px'}}
                                            className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                            id="tw-calculator-target-select"
                                            role="combobox"
                                            aria-controls="tw-calculator-target-select-listbox"
                                            aria-expanded="false"
                                            aria-autocomplete="none"
                                            type="button"
                                            ref={buttonRefListaTransfer}
                                            onClick={() => {
                                              setMintValue('');
                                              setRecebeValue('');
                                                setShowListaTransfer(!showListaTransfer);
                                            
                                
                                              }}
                                            
                                            
                                          >
                                            <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                              <div className="d-flex flex-column justify-content-center">
                                                {transferType.abbreviation ==='pix' ?
                                              <i style={{marginLeft:'-5px'}} className={currencyFlagPIXClass} /> : <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              version="1.1"
                                              width={25}
                                              height={25}
                                              viewBox="0 0 256 256"
                                              xmlSpace="preserve"
                                              
                                            >
                                              <defs></defs>
                                              <g
                                                style={{
                                                  stroke: "none",
                                                  strokeWidth: 0,
                                                  strokeDasharray: "none",
                                                  strokeLinecap: "butt",
                                                  strokeLinejoin: "miter",
                                                  strokeMiterlimit: 10,
                                                  fill: "none",
                                                  fillRule: "nonzero",
                                                  opacity: 1
                                                }}
                                                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                                              >
                                                <path
                                                  d="M 84.668 38.004 v -6.27 H 90 V 20 L 45 3.034 L 0 20 v 11.734 h 5.332 v 6.27 h 4.818 v 30.892 H 5.332 v 6.271 H 0 v 11.8 h 90 v -11.8 h -5.332 v -6.271 H 79.85 V 38.004 H 84.668 z M 81.668 35.004 H 66.332 v -3.27 h 15.336 V 35.004 z M 63.332 68.896 v 6.271 h -7.664 v -6.271 H 50.85 V 38.004 h 4.818 v -6.27 h 7.664 v 6.27 h 4.818 v 30.892 H 63.332 z M 26.668 38.004 v -6.27 h 7.664 v 6.27 h 4.818 v 30.892 h -4.818 v 6.271 h -7.664 v -6.271 H 21.85 V 38.004 H 26.668 z M 42.15 68.896 V 38.004 h 5.7 v 30.892 H 42.15 z M 37.332 35.004 v -3.27 h 15.336 v 3.27 H 37.332 z M 37.332 71.896 h 15.336 v 3.271 H 37.332 V 71.896 z M 3 22.075 L 45 6.24 l 42 15.835 v 6.659 H 3 V 22.075 z M 8.332 31.734 h 15.336 v 3.27 H 8.332 V 31.734 z M 13.15 38.004 h 5.7 v 30.892 h -5.7 V 38.004 z M 8.332 71.896 h 15.336 v 3.271 H 8.332 V 71.896 z M 87 83.966 H 3 v -5.8 h 84 V 83.966 z M 81.668 75.166 H 66.332 v -3.271 h 15.336 V 75.166 z M 76.85 68.896 H 71.15 V 38.004 h 5.699 V 68.896 z"
                                                  style={{
                                                    stroke: "none",
                                                    strokeWidth: 1,
                                                    strokeDasharray: "none",
                                                    strokeLinecap: "butt",
                                                    strokeLinejoin: "miter",
                                                    strokeMiterlimit: 10,
                                                    fill: "rgb(0,0,0)",
                                                    fillRule: "nonzero",
                                                    opacity: 1
                                                  }}
                                                  transform=" matrix(1 0 0 1 0 0) "
                                                  strokeLinecap="round"
                                                />
                                              </g>
                                            </svg>}
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                <span style={{fontSize:'18px', marginRight:"25px"}} className="np-text-title-subsection tw-money-input__text">
                                                  PIX
                                                  </span>
                                                </span>
                                         
                                              </div>
                                              
                                            </div>
                                         
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                              style={{marginBottom:'40px'}}
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>


                                    {users?.twoFAActivated && (pixKeyType==='CPF' || pixKeyType==='CNPJ') &&
                            <>
                                  <label  htmlFor="tw-calculator-target">
                                    2FA{" "}
                                  </label>
                  <div className="verify-input-container">
                    <div className="code-input-container">
                    <div className={`code-section${codeoneActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_2');
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                          }}
                          onFocus={() => {
                            SetcodeoneActive(true);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_1', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codetwoActive ? ' active' : ''}`}>

                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_3');
                            SetcodetreeActive(true);
                            SetcodetwoActive(false);
                          }}
                          onFocus={() => {
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_2', { required: true })}
                        />

                      </div>
                      <div className={`code-section${codetreeActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_4');
                            SetcodefourActive(true);
                            SetcodetreeActive(false);
                          }}
                          onFocus={() => {
                            SetcodetreeActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_3', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefourActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_5');
                            SetcodefiveActive(true);
                            SetcodefourActive(false);
                          }}
                          onFocus={() => {
                            SetcodefourActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_4', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefiveActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_6');
                            SetcodesixActive(true);
                            SetcodefiveActive(false);
                          }}
                          onFocus={() => {
                            SetcodefiveActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_5', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codesixActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onFocus={() => {
                            SetcodesixActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                          }}
                          {...register('code_6', { required: true })}
                        />
                      </div>
                    </div>
                  </div></>}

                                  </div> : 
                                  <div >

<div style={{ display: 'flex' }}>
    <div style={{ marginBottom: "0px" }}>
        <label htmlFor="tw-calculator-target">
            {t('agencia_bancaria')}{" "}
        </label>
        <div className="tw-money-input form-control" style={{ display: 'flex', height: '50px', border: '1px solid black', borderRadius: '15px', width: "180px" }}>
            <input
                className="input-transfer"
                id="tw-calculator-target"
                inputMode="text"
                placeholder={t('1234')}
                autoComplete="off"
                onChange={handleBranchCodeChange}
                //onBlur={handlePixKeyFormat}
                value={branchCode}
                style={{ width: "150px" }}
            />
        </div>
    </div>

    <div style={{ marginBottom: "0px" }}>
        <label htmlFor="tw-calculator-target">
            {t('conta_bancaria')}{" "}
        </label>
        <div className="tw-money-input form-control" style={{ display: 'flex', height: '50px', border: '1px solid black', borderRadius: '15px', width: "180px", marginLeft:'8px' }}>
            <input
                className="input-transfer"
                id="tw-calculator-target"
                inputMode="text"
                placeholder={t('12387-1')}
                autoComplete="off"
                onChange={handleAccountNumberChange}
                //onBlur={handlePixKeyFormat}
                value={accountNumber}
                style={{ width: "150px" }}
            />


<div className="input-group-btn amount-currency-select-btn">
                                      <div style={{width:'100px'}} className="np-select btn-block btn-group">
                                          <button
                                           style={{minHeight:'70px', height:'70px'}}
                                            className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                            id="tw-calculator-target-select"
                                            role="combobox"
                                            aria-controls="tw-calculator-target-select-listbox"
                                            aria-expanded="false"
                                            aria-autocomplete="none"
                                            type="button"
                                            ref={buttonRefListaTransfer}
                                            onClick={() => {
                                              setMintValue('');
                                              setRecebeValue('');
                                                setShowListaTransfer(!showListaTransfer);
                                            
                                
                                              }}
                                            
                                            
                                          >
                                            <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                              <div className="d-flex flex-column justify-content-center">
                                                {transferType.abbreviation ==='pix' ?
                                              <i style={{marginLeft:'-5px'}} className={currencyFlagPIXClass} /> : <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              version="1.1"
                                              width={25}
                                              height={25}
                                              viewBox="0 0 256 256"
                                              xmlSpace="preserve"
                                              
                                            >
                                              <defs></defs>
                                              <g
                                                style={{
                                                  stroke: "none",
                                                  strokeWidth: 0,
                                                  strokeDasharray: "none",
                                                  strokeLinecap: "butt",
                                                  strokeLinejoin: "miter",
                                                  strokeMiterlimit: 10,
                                                  fill: "none",
                                                  fillRule: "nonzero",
                                                  opacity: 1
                                                }}
                                                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                                              >
                                                <path
                                                  d="M 84.668 38.004 v -6.27 H 90 V 20 L 45 3.034 L 0 20 v 11.734 h 5.332 v 6.27 h 4.818 v 30.892 H 5.332 v 6.271 H 0 v 11.8 h 90 v -11.8 h -5.332 v -6.271 H 79.85 V 38.004 H 84.668 z M 81.668 35.004 H 66.332 v -3.27 h 15.336 V 35.004 z M 63.332 68.896 v 6.271 h -7.664 v -6.271 H 50.85 V 38.004 h 4.818 v -6.27 h 7.664 v 6.27 h 4.818 v 30.892 H 63.332 z M 26.668 38.004 v -6.27 h 7.664 v 6.27 h 4.818 v 30.892 h -4.818 v 6.271 h -7.664 v -6.271 H 21.85 V 38.004 H 26.668 z M 42.15 68.896 V 38.004 h 5.7 v 30.892 H 42.15 z M 37.332 35.004 v -3.27 h 15.336 v 3.27 H 37.332 z M 37.332 71.896 h 15.336 v 3.271 H 37.332 V 71.896 z M 3 22.075 L 45 6.24 l 42 15.835 v 6.659 H 3 V 22.075 z M 8.332 31.734 h 15.336 v 3.27 H 8.332 V 31.734 z M 13.15 38.004 h 5.7 v 30.892 h -5.7 V 38.004 z M 8.332 71.896 h 15.336 v 3.271 H 8.332 V 71.896 z M 87 83.966 H 3 v -5.8 h 84 V 83.966 z M 81.668 75.166 H 66.332 v -3.271 h 15.336 V 75.166 z M 76.85 68.896 H 71.15 V 38.004 h 5.699 V 68.896 z"
                                                  style={{
                                                    stroke: "none",
                                                    strokeWidth: 1,
                                                    strokeDasharray: "none",
                                                    strokeLinecap: "butt",
                                                    strokeLinejoin: "miter",
                                                    strokeMiterlimit: 10,
                                                    fill: "rgb(0,0,0)",
                                                    fillRule: "nonzero",
                                                    opacity: 1
                                                  }}
                                                  transform=" matrix(1 0 0 1 0 0) "
                                                  strokeLinecap="round"
                                                />
                                              </g>
                                            </svg>}
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                <span style={{fontSize:'18px', marginRight:"25px"}} className="np-text-title-subsection tw-money-input__text">
                                                  PIX
                                                  </span>
                                                </span>
                                         
                                              </div>
                                              
                                            </div>
                                         
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                              style={{marginBottom:'40px'}}
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>


        </div>
    </div>
</div>


<div style={{ marginBottom: "0px" }}>
        <label htmlFor="tw-calculator-target">
            {t('banco')}{" "}
        </label>
        <div>
        <Select
            id="account-type-selector"
            className="account-type-selector"
            value={selectedBank}
            onChange={handleChange}
             onMouseDown={stopPropagation}
            options={bankOptions}
            placeholder="Selecione um banco..."
            isSearchable={true}
            styles={{ 
                control: (base) => ({
                    ...base,
                    height: '50px',
                    border: '1px solid black',
                    borderRadius: '15px',
                    width: '450px'
                })
            }}
            onClick={stopPropagation}
        />
        </div>
    </div>

<div style={{ marginBottom: "0px" }}>
        <label htmlFor="tw-calculator-target">
            {t('tipo_de_conta')}{" "}
        </label>
        <div>
    <select
            id="account-type-selector"
            className="account-type-selector"
            onChange={handleAccountTypeChange}
            value={accountType}
            style={{ 
                height: '50px', 
                border: '1px solid black', 
                borderRadius: '15px', 
                width: "450px",
                padding: '10px',
                backgroundColor: 'white',
                color: 'black',
                fontSize: '16px'
            }}
        >
            <option value="checking">{t('Conta corrente')}</option>
            <option value="savings">{t('Conta poupança')}</option>
        </select>
        </div>
    </div>
    <div style={{ marginBottom: "0px" }}>
        <label htmlFor="tw-calculator-target">
            {t('nome_do_beneficiario')}{" "}
        </label>
        <div className="tw-money-input form-control" style={{ display: 'flex', height: '50px', border: '1px solid black', borderRadius: '15px', width: "450px" }}>
            <input
                className="input-transfer"
                id="tw-calculator-target"
                inputMode="text"
                placeholder={t('Texto')}
                autoComplete="off"
                onChange={handleFullNameChange}
                //onBlur={handlePixKeyFormat}
                value={fullName}
                style={{ width: "300px" }}
            />
        </div>
    </div>


       <div style={{ marginBottom: "0px" }}>
        <label htmlFor="tw-calculator-target">
            {t('cpf_do_beneficiario')}{" "}
        </label>
        <div className="tw-money-input form-control" style={{ display: 'flex', height: '50px', border: '1px solid black', borderRadius: '15px', width: "450px" }}>
            <input
                className="input-transfer"
                id="tw-calculator-target"
                inputMode="text"
                placeholder='Digite o CPF ou CNPJ do beneficiário'
                autoComplete="off"
                onChange={handleCpfCnpjChange}
                onBlur={handleCpfCnpjFormat}
                value={CpfCnpj}
                style={{ width: "300px" }}
            />
        </div>
    </div>

                                 
                                

                                  </div>}
                                  
                                  { !(pixKeyType==='CPF' || pixKeyType==='CNPJ') ? <div style={{marginBottom:"20px"}} className="">
                                  <label htmlFor="tw-calculator-target">
                                  {t('cpf_do_beneficiario')}{" "}
                                  </label>
                                  <div  className="tw-money-input form-control" style={{display:'flex', height:'50px', border:'1px solid black', borderRadius:'15px', width:"450px"}}>
                                    <input
                                      className="input-transfer"
                                      id="tw-calculator-target"
                                      inputMode="text"
                                      placeholder="Digite o CPF ou CNPJ"
                                      autoComplete="off"
                                      onChange={handleCpfCnpjChange}
                                      onBlur={handleCpfCnpjFormat}
                                      value={CpfCnpj}
                                      style={{width:"300px"}}
                                      
                                      
                                    />
                                    <div className="input-group-btn amount-currency-select-btn">
                                    <div style={{width:'100px', marginLeft:'16px'}} className="np-select btn-block btn-group">
                                        <button
                                         style={{minHeight:'70px', height:'70px'}}
                                          className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                          id="tw-calculator-target-select"
                                          role="combobox"
                                          aria-controls="tw-calculator-target-select-listbox"
                                          aria-expanded="false"
                                          aria-autocomplete="none"
                                          type="button"
                                          
                                          
                                        >
                                          <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                            <div className="d-flex flex-column justify-content-center">
                                            <i style={{marginLeft:'-5px'}} className={currencyFlagPIXClass} />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                              <span className="np-text-body-large text-ellipsis">
                                              <span style={{fontSize:'18px', marginRight:"25px"}} className="np-text-title-subsection tw-money-input__text">
                                                PIX
                                                </span>
                                              </span>
                                       
                                            </div>
                                            
                                          </div>
                                       
                                          <span
                                            className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                            aria-hidden="true"
                                            role="presentation"
                                            data-testid="chevron-up-icon"
                                            style={{marginBottom:'40px'}}
                                          >
                                            <svg
                                              width={16}
                                              height={16}
                                              fill="currentColor"
                                              focusable="false"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                            </svg>
                                          </span>
                                        </button>

                                      </div>

                                    </div>

                                  </div>
                                 
                          

                                </div> : <div></div>}
                                </>
                                  
                                  ) : (


<di></di>
) }

                                  {(selectedTokenRecebe.abbreviation !== selectedToken.abbreviation ) && !(selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='USD' ) && !(selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL' ) && !(selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD' )  ? (


                                  <div style={{fontSize:"14px", marginTop:'-9px'}} className="tw-calculator-effective-rate">
                                    
                               
                                    <strong>1 BRL = {isNaN(quoteResult?.amountBrl) || isNaN(quoteResult?.amountUsd)
  ? "Carregando... "
  : (parseFloat(quoteResult?.amountBrl) / parseFloat(quoteResult?.amountUsd)).toFixed(4)
+" BRL"}
 </strong>{" "}




                                    <span className="np-popover">
                                      <span className="d-inline-block">
                                        <button
                                          className="btn btn-md np-btn np-btn-md btn-accent btn-priority-3 btn-unstyled p-a-0"
                                          type="button"
                                          style={{
                                            height: "auto",
                                            verticalAlign: "bottom",
                                            fontSize:'14px'
                                          }}
                                        >
                                          {t('cambio_efetivo')} (VET)
                                        </button>
                                      </span>
                                    </span>
                                  </div> ) :(<div></div>)}

                                  { (selectedTokenRecebe.abbreviation==='BRL' && pixKey!=='' && pixKeyValid) ? (
                                    <>

                                  <hr className="" style={{marginTop:'10px'}} />

                                  <div className="" style={{marginTop:'-25px'}}>
                                  <strong className="tw-review-details-card__title">
                                    <small className="ng-binding">
                                      {" "}
                                      {t('dados_do_beneficiario')}{" "}
                                    </small>
                                  </strong>
                               
                                </div>
                               
                                <p
                                  ng-repeat="detail in $ctrl.contact.display.details track by $index"
                                  className="tw-review-element  ng-scope"
                                  style={{}}
                                >
                                  <small style={{fontSize:'14px'}} className="tw-review-element__name ng-binding">
                                  {t('titular')}
                                  </small>
                                  <span style={{fontSize:'14px', marginTop:"-2px"}} className="tw-review-element__value text-primary ng-binding">
                                  {toTitleCase(pixInfo?.name || '')}
                                  </span>
                                </p>
                            
                               
                                {/* end ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                               
                                {/* end ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                                <p
                                  className="m-b-0 tw-review-element ng-hide"
                                  ng-show="$ctrl.deliveryMethod === 'LINK'"
                                  style={{}}
                                >
                                  <small style={{fontSize:'14px'}} className="tw-review-element__name ng-binding">
                                  {t('banco')}
                                  </small>
                                  <span style={{fontSize:'14px', marginTop:"-2px"}} className="tw-review-element__value text-primary ng-binding">
                                  {toTitleCase(pixInfo?.bankName || '')}
                                  </span>
                                </p>
                                </>) : (
                                  <div></div>
                                )}
                                
                                  <div className="m-t-3 tw-calculator-notices"  >
                                    <div
                                      role="alert"
                                      className="alert d-flex alert-neutral"
                                      style={{height:'20px', marginTop:"-20px"}}
                                    >
                                      <div
                                        className="alert__content d-flex flex-grow-1 desktop"
                                        data-testid="desktop"
                                      >
                                        <span
                                          data-testid="status-icon"
                                          className="status-circle status-circle-lg neutral"
                                          style={{display:'flex',width:'30px', height:'30px', alignItems:'center', justifyContent:'center', alignContent:'center', verticalAlign:"center", marginTop:'-15px'}}
                                        >
                                          <span
                                            className="tw-icon tw-icon-info status-icon light"
                                            aria-hidden="true"
                                            role="presentation"
                                            data-testid="info-icon"
                                            
                                          >
                                            <svg
                                              width={16}
                                              height={16}
                                              fill="currentColor"
                                              focusable="false"
                                              viewBox="0 0 24 24"
                                              
                                            >
                                              <path d="M11.143 9.857h1.714v9.857h-1.714z" />
                                              <circle cx={12} cy={6} r="1.714" />
                                            </svg>
                                          </span>
                                        </span>


                                        <div style={{height:'10px', fontSize:'14px', lineHeight:"6px"}} className="alert__message">
                                          <span  className="">
                                            <span >
                                            {t('termos')}
                                            </span>


                                            <a
                                            href="https://brla.digital/terms-and-services-pt.pdf"
                                            target="_blank"
                                            className="np-link  d-inline-flex m-t-1"
                                            rel="noreferrer"
                                            style={{fontSize:'14px'}}
                                          >
                                            {t('saiba_mais')}
                                            <span
                                              className="tw-icon tw-icon-navigate-away "
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="navigate-away-icon"
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                                style={{width:'10px', height:"10px"}}
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M12.857 1.287h8.571a.86.86 0 0 1 .857.857v8.572h-1.714V4.2l-9.428 9.429-1.2-1.2 9.428-9.429h-6.514V1.287ZM3.429 20.998H18v-6h1.714v6.857a.86.86 0 0 1-.857.858H2.572a.86.86 0 0 1-.858-.858V4.713a.86.86 0 0 1 .857-.858h6V5.57H3.43v15.428Z"
                                                />
                                              </svg>
                                            </span>
                                          </a>

                                          </span>
                                
                                        </div>
                                       
                                      </div>
                                    </div>
                                  </div>
                                  {ishighValue && <p style={{display:'flex', color: 'red', fontSize:'12px', textAlign:'center', justifyContent:'center', alignItems:"center" }}>{t('valor_menor')}</p>}
 <p style={{ color: 'red', fontSize:'12px', textAlign:'center' }}>{message.text}</p>

                                  <div className="d-flex m-t-2">
                       
                                    <button
                                      className="btn btn-md np-btn np-btn-md btn-block np-btn-block btn-positive btn-priority-1 m-t-0"
                                      data-testid="continue-button"
                                      type="submit"
                                    //  disabled={((mintValue==='' || recebeValue==='' || mintValue===0 || recebeValue===0 || walletValue==='' ) && (mintValue==='' || recebeValue==='' || mintValue===0 || recebeValue===0  || pixKey===''  || !pixKeyValid || !ValidCpfCnpj || (!isValid && users?.twoFAActivated ))) || isInvalid || isLoading  || (isNaN(SocketMessage?.data?.baseFee) && ((selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='BRL') || (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USD')       ) ) ||    (!isValid && users?.twoFAActivated   ) }
                                    >
                                      {isLoading ? t('carregando') + "..." : t('confirm_transfer')}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div style={{ width: 32, display: "none" }} />
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>) : (
                      <div style={{display:"flex", justifyContent:"center", paddingTop:"80px", paddingBottom:"100px"}} className="row mx-auto">                 
                                 
  <img style={{width:'100px', height:'100px'}} src={Checked} />
  
  <p style={{marginTop:"20px", marginBottom:'10px'}}>{t('complete')}</p>
</div>

                    )}
                 
               
                 </>
  )}
          </section>
        </div> 
        </form>    
    </>
  );
});
export default Payment
