import WalletHeader from '../../Components/WalletHeader';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './css/styles.css';
import './css/226085e3c6877b32.css';
import ferramentaImage from '../Home/images/ferramenta2.png';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES, USDC_CONTRACT_ADDRESSES, USDT_CONTRACT_ADDRESSES, CRYPTO_FRIENDLY, POLYGONURL} from '../../variaveis';
import FooterLogged from '../../Components/FooterLogged';
import { BRLAContractAbi, USDCContractAbi,USDTContractAbi  } from '../../utils/abis';
import Web3 from 'web3';
import { Buffer } from "buffer/";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AddBank from '../AddBank';
import MetamaskIcon from './MetaMask_Fox.svg'
import BRLAIcon from '../Swap/logo_brla.png';
import UsdcIcon from '../Swap/usdc_symbol.png'
import Deposit from '../../Components/Deposit';
import Transfer from '../../Components/Transfer';
import Transactions from '../../Components/Transactions';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import Brazil from './brazil.png';
import Swap from './swap.png'
import SwapBa from '../../Components/Swap';
import { useTranslation } from 'react-i18next';
import brazilFlag from '../Home/images/brazil.png'; // import your flag images
import usaFlag from '../Home/images/usa.png';
import Payment from '../../Components/Payment/payment';
import axios from 'axios';
const responsiveStyle = {
  width: '100%',
  maxWidth: '100%',
  overflowX: 'hidden',
};
function Wallet() {
  
  const [tooltip, setTooltip] = React.useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pendingRequests, setPendingRequests] = useState(1);
  const [users, setUsers] = useState([]);
  const [balance, setBalance] = useState([]);
  const [balanceUsd, setBalanceUsd] = useState([]);
  const [balanceUsdt, setBalanceUsdt] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [kychistoryData, setKycHistoryData] = useState([]);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [showSwap, setShowSwap] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [usdSpot, setUsdSpot] = useState(null);



  const buttonRefDeposit = useRef(null);
  const buttonRefTransfer = useRef(null);
  const { t, i18n} = useTranslation();
  const buttonRefSwap = useRef(null);
  const buttonRefPayment = useRef(null);
  const [Token, setToken] = useState('TOKEN');
  useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      setUsers(JSON.parse(loadedUsers));
    }


    // Obtem os dados do histórico do localStorage
    const loadedHistoryData = localStorage.getItem('historyData');
    if (loadedHistoryData) {
      let historyData = JSON.parse(loadedHistoryData);
    
      // Ordenando os dados do histórico pela data de atualização
      historyData = historyData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    
      // Criando um objeto onde a chave é o ID e o valor é o item do histórico
      setHistoryData(historyData);
    };


    const loadedKYCHistory = localStorage.getItem('kychistory');
    if (loadedKYCHistory) {
      const KycHistory = JSON.parse(loadedKYCHistory)
      // Criando um objeto onde a chave é o ID e o valor é o item do histórico
      setKycHistoryData(KycHistory);


    };



    const fetchBalance = async () => {
    
   
      // Cria uma nova instância do web3 com o Infura
      const web3 = new Web3(new Web3.providers.HttpProvider(POLYGONURL));

      // Mapeia cada carteira para um objeto que inclui o saldo BRLA
      
          const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[137];
          const USDC_CONTRACT_ADDRESS = USDC_CONTRACT_ADDRESSES[137];
          const USDT_CONTRACT_ADDRESS = USDT_CONTRACT_ADDRESSES[137];
          const contract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
          const contractUsd = new web3.eth.Contract(USDCContractAbi, USDC_CONTRACT_ADDRESS);
          const contractUsdt = new web3.eth.Contract(USDTContractAbi, USDT_CONTRACT_ADDRESS);
          const balance = await contract.methods.balanceOf(users?.wallets?.evm).call();
          const balanceUsd = await contractUsd.methods.balanceOf(users?.wallets?.evm).call();
          const balanceUsdt = await contractUsdt.methods.balanceOf(users?.wallets?.evm).call();
          setBalance(balance)
          setBalanceUsd(balanceUsd);
          setBalanceUsdt(balanceUsdt);
          
      
      

      
  };

  if (users?.wallets?.evm) {
    fetchBalance();
  }
  
  setPendingRequests(0);

  console.log("caiu aqui 1")
  }, [users?.wallets?.evm]);

  useEffect(() => {
console.log("balance mudou: ", balance)
  }, [balance]);

  function numberWithThousandSeparator(number) {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

 





  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
  
  
  const navigate = useNavigate();
  
   

      useEffect(() => {
   
    }, []);
    const depositRef = useRef();
    const transferRef = useRef();
    const swapRef = useRef();
    const paymentRef = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showDeposit && depositRef.current && !depositRef.current.contains(event.target) && !buttonRefDeposit.current.contains(event.target)) {
          setShowDeposit(false);
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showDeposit]);
    

    function hasParentWithMatchingClass(element) {
      if (element.id.includes('react-select')) {
          return true;
      }
      
  }
  
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showTransfer &&
          transferRef.current && 
          !transferRef.current.contains(event.target) &&
          !buttonRefTransfer.current.contains(event.target) &&
          !hasParentWithMatchingClass(event.target)) {
        setShowTransfer(false);
        console.log(event.target);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showTransfer]);
  


    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showSwap && swapRef.current && !swapRef.current.contains(event.target) && !buttonRefSwap.current.contains(event.target)) {
          setShowSwap(false);
         
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showSwap]);


    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showPayment && paymentRef.current && !paymentRef.current.contains(event.target) && !buttonRefPayment.current.contains(event.target)) {
          setShowPayment(false);
         
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showPayment]);    
    

    useEffect(() => {
      // Se não há requisições pendentes, então as requisições maamacabaram
      if (pendingRequests === 0) {
        // Adiciona um delay de 0.2 segundos antes de remover o estado de carregamento
        setIsLoading(false);
      } 
    }, [pendingRequests]);

    useEffect(() => {
      // Fetch the usd_spot and usdt_spread from the API
      const fetchExchangeRate = async () => {
        try {
          const response = await axios.get('https://api.betgenius.digital:4567/usdt');
          const { usd_spot } = response.data;
          setUsdSpot(parseFloat(usd_spot)); // Ensure usd_spot is a number
        } catch (error) {
          console.error("Error fetching exchange rate:", error);
        }
      };
  
      fetchExchangeRate();
    }, []);

    
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

    if (isLoading) {
      // Retorne um spinner de loading, um placeholder ou null
      return <div></div>;
    }


    const statuses = ['Pendente', 'Completo', 'Cancelada'];




 

    





return (
  
<div  id="__next" style={{overflowX:'hidden'}}>
  

  <div >
    <div
      style={{ backgroundColor: "#08383f !important" }}
      className="grid w-full min-h-screen font-circular"
    >
      <WalletHeader/>
      <main className="flex-grow flex flex-col bg-surface-background">
        <div className="grid flex-1 gap-y-12 content-start">
          <div data-testid="payments-page-header" className="px-16 mt-12">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
              <header  className="cb-header " >
                <div className="cb-header-head primary">
                  
                  <div className="actions main-container">
                    <div style={{display:'block', marginRight:'65px'}}>
                  <h1 style={{ color: "#08383f", width:"200px" }} className="title  margin-titulo main-container-8">
                  {t('welcome')}, {users?.fullName.split(' ')[0]}
                  </h1>
                  <div className="cb-header-numbers mt-7 margin-titulo main-container-8">
                  <div className="cb-number-box">
                  <h3 className="cb-number-box-amount primary lg ">
  R$&nbsp;
  {
  isNaN(balance) 
    ? "Loading..." 
    : `${Math.floor((balance / Math.pow(10, 18) + ((balanceUsd / Math.pow(10, 6)) + (balanceUsdt / Math.pow(10, 6))) * usdSpot) * 100) / 100}`
}


  <span className="currency">BRL</span>
</h3>

                    <h2  className="cb-number-box-title">
                      <span className="flex flex-row">
                      {t('total_value')}&nbsp;
                    
                      </span>
                    </h2>
                  </div>
                </div>

                  </div>
                
              {/*    <button
                      style={{ backgroundColor: "#08383f" }}
                      type="button"
                      className="cb-button base primary"
                      data-testid="account-funding-button"
                      onClick={handleButtonWallet}
                    >
                      <span>Add wallet</span>
    </button> */}

            <div style={{marginRight:'-50px'}}>        
<button
  ref={buttonRefTransfer}
  style={{ backgroundColor: "#08383f", marginRight: "10px" }}
  type="button"
  className="cb-button base primary"
  data-testid="account-funding-button"
  onClick={() => {
    setShowTransfer(!showTransfer);
  }}
>
  <span>{t('transfer')}</span>
</button>
    
    
               
                    
    <button
  ref={buttonRefDeposit}
  style={{ backgroundColor: "#08383f", marginLeft: "10px" }}
  type="button"
  className="cb-button base primary"
  data-testid="account-funding-button"
  onClick={() => {
    setShowDeposit(!showDeposit);
  }}
>
  <span>{t('deposit')}</span>
</button>
    

<button
  ref={buttonRefSwap}
  style={{ backgroundColor: "#08383f", marginLeft: "10px" }}
  type="button"
  className="cb-button base primary"
  data-testid="account-funding-button"
  onClick={() => {
    setShowSwap(!showSwap);
  }}
>
  <span>{t('convert')}</span>
</button>


<button
  ref={buttonRefPayment}
  style={{ backgroundColor: "#08383f", marginLeft: "10px" }}
  type="button"
  className="cb-button base primary"
  data-testid="account-funding-button"
  onClick={() => {
    setShowPayment(!showPayment);
  }}
>
  <span>{t('Payment')}</span>
</button>

</div>
                  

                  </div>
                  
                </div>
                
                {showDeposit && <div ref={depositRef} className="overlay"></div>}
{showTransfer && <div ref={transferRef} className="overlay"></div>}
{showSwap && <div ref={swapRef} className="overlay"></div>}
{showPayment && <div ref={paymentRef} className="overlay"></div>}
{showDeposit && <Deposit ref={depositRef} />}    
{showTransfer && <Transfer ref={transferRef} />}
{showPayment && <Payment ref={paymentRef} />}   
{showSwap && <SwapBa ref={swapRef} />}   

                           
              </header>
            </div>
          </div>
          <div
  className="BalanceCarouselSection_carousel__w4SRU"
  data-id="balance-carousel"
  style={{zIndex:10, justifyContent:'center'}}
>
  <a
    className="BalanceCard_balanceCard__KywHe"
    data-testid="BalanceCard"
    style={{ opacity: 1, background:'white' }}
    aria-disabled
    
  >
    <div aria-hidden="true">
      <div className="tw-avatar BalanceCardAvatar_balanceAvatarRoundCurrency__TXc6P tw-avatar--48 tw-avatar--thumbnail">
        <div
          className="tw-avatar__content"
          style={{ backgroundColor: "transparent" }}
        >
          <div style={{ backgroundImage: "url('https://brla.digital/static/media/brazil.png')" }} className="currency-flag brazil" />
        </div>
      </div>
    </div>
    <div className="BalanceCard_balanceCardText__cWaGT">
      <h2
        style={{
          fontSize: "1.375rem",
          letterSpacing: "normal",
          overflowWrap: "normal",
          whiteSpace: "nowrap",
          color:'black'
        }}
        className="m-b-0 title-3"
      >
       {
  isNaN(balance) 
    ? "Loading..." 
    : `${Math.floor((balance / Math.pow(10, 18)) * 100) / 100}`
}
      </h2>
      <div className="np-text-body-default BalanceCard_balanceTextContentSecondary__Xz65Y BalanceCard_balanceTextContentSecondaryOverflow___KjxL">
      {t('real')}
      </div>
    </div>
  </a>

  <a
    className="BalanceCard_balanceCard__KywHe"
    data-testid="BalanceCard"
    style={{ opacity: 1, background:'white' }}
    aria-disabled
  >
    <div aria-hidden="true">
      <div className="tw-avatar BalanceCardAvatar_balanceAvatarRoundCurrency__TXc6P tw-avatar--48 tw-avatar--thumbnail">
        <div
          className="tw-avatar__content"
          style={{ backgroundColor: "transparent" }}
        >
          <div style={{ backgroundImage: "url('https://brla.digital/static/media/usdc.png')" }} className="currency-flag " />
        </div>
      </div>
    </div>
    <div className="BalanceCard_balanceCardText__cWaGT">
      <h2
        style={{
          fontSize: "1.375rem",
          letterSpacing: "normal",
          overflowWrap: "normal",
          whiteSpace: "nowrap",
          color:'black'
        }}
        className="m-b-0 title-3"
        
      >
        {
  isNaN(balanceUsd) 
    ? "Loading..." 
    : `${Math.floor((balanceUsd / Math.pow(10, 6)) * 100) / 100}`
}

      </h2>
      <div className="np-text-body-default BalanceCard_balanceTextContentSecondary__Xz65Y BalanceCard_balanceTextContentSecondaryOverflow___KjxL">
      {t('dolar')}
      </div>
    </div>
  </a>
{ !users?.autoConvert &&

  <a
    className="BalanceCard_balanceCard__KywHe"
    data-testid="BalanceCard"
    style={{ opacity: 1, background:'white' }}
    aria-disabled
  >
    <div aria-hidden="true">
      <div className="tw-avatar BalanceCardAvatar_balanceAvatarRoundCurrency__TXc6P tw-avatar--48 tw-avatar--thumbnail">
        <div
          className="tw-avatar__content"
          style={{ backgroundColor: "transparent" }}
        >
          <div style={{ backgroundImage: "url('https://brla.digital/static/media/usdt.png')" }} className="currency-flag " />
        </div>
      </div>
    </div>
    <div className="BalanceCard_balanceCardText__cWaGT">
      <h2
        style={{
          fontSize: "1.375rem",
          letterSpacing: "normal",
          overflowWrap: "normal",
          whiteSpace: "nowrap",
          color:'black'
        }}
        className="m-b-0 title-3"
        
      >
        {
  isNaN(balanceUsdt) 
    ? "Loading..." 
    : `${Math.floor((balanceUsdt / Math.pow(10, 6)) * 100) / 100}`
}

      </h2>
      <div className="np-text-body-default BalanceCard_balanceTextContentSecondary__Xz65Y BalanceCard_balanceTextContentSecondaryOverflow___KjxL">
        USD Tether
      </div>
    </div>
  </a>}

</div>

          <div className="px-16 pt-0 ">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl ">
           {/*   <div className="grid grid-cols-2 gap-10 main-container-2" >
                <div
                  className="cb-card surface_primary shadow_md flex flex-col bg-blue-75 bg-gradient-to-br from-blue-75 to-orange-50"
                  data-testid="get-api-card"
                >
                  <div className="content flex flex-col flex-1 gap-6">
                    <div className="flex flex-col gap-4">
                      <h2 className="text-2xl text-#08383f-700 font-circular-bold">
                        Get a API key
                      </h2>
                      <p>
                        Use the API key to test our functionalities or
                        explore our sample app.
                      </p>
                    </div>
                    <div className="flex flex-1 items-end">
                      <div className="flex flex-wrap gap-4">
                        <a
                          className="cb-button base secondary w-auto text-sm text-#08383f-600"
                          href="#"
                          role="link"
                          rel="noopener noreferrer"
                        >
                          get api key
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="cb-card surface_primary shadow_md flex flex-col"
                  data-testid="see-docs-card"
                >
                  <div className="content flex flex-col flex-1 pl-2">
                    <div className="flex">
                      <div className="my-auto pr-3">
                        <img
                          alt="building with a wrench pencil ruler"
                          srcSet={ferramentaImage}
                          src={ferramentaImage}
                          width={165}
                          height={165}
                          decoding="async"
                          data-nimg={1}
                          loading="lazy"
                          style={{ color: "transparent", objectFit: "contain" }}
                        />
                      </div>
                      <div className="flex flex-col justify-between gap-6">
                        <div className="flex flex-col gap-4">
                          <h2 className="text-2xl text-#08383f-700 font-circular-bold">
                            See documentations
                          </h2>
                          <p>
                            Find guides and references to help with integration.
                          </p>
                        </div>
                        <div className="flex flex-1 items-end">
                          <div className="flex flex-wrap gap-4">
                            <a
                              className="cb-button base secondary w-auto text-sm text-#08383f-600"
                              href="https://api-documentation.brla.digital/"
                              role="link"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              see guides
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    </div> */}
           <Transactions limit={10} showLink={true} />


            </div>
          </div>
        </div>
  <FooterLogged/>
      </main>
    </div>
  </div>
  <ToastContainer />
  <Tooltip id="my-tooltip" />
</div>)}
export default Wallet;
