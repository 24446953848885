import { forwardRef, useState, useEffect, useRef, useCallback } from 'react';
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES, MANTEINENCETOKEN, USDC_CONTRACT_ADDRESSES, DEFAULTCOIN, USDT_CONTRACT_ADDRESSES, POLYGONURL} from '../../variaveis';
import resets from './_resets.module.css';
import classes from './Section.module.css';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Web3 from 'web3';
import { Buffer } from "buffer/";
import { BRLAContractAbi, USDCContractAbi, USDTContractAbi } from '../../utils/abis';
import usaFlag from './usa_flag.png';
import brazilFlag from './brazil_flag.png';
import ListaCoins from '../../Components/ListaCoins';
import ListaCoinsRecebe from '../../Components/ListaCoinsRecebe';
import Checked from './checked.png';
import { useContext } from 'react';
import socketContext from '../Websocket/WebSocketContext';
import { useTranslation } from 'react-i18next';
import ListaTransfer from '../ListaTransfer';
import Select from 'react-select';
import ListaChains from '../ListaChains';


/* @figmaId 333:2369 */
const Transfer = forwardRef((props, ref) => {
  const [message, setMessage] = useState({ text: '', color: '' });
  const { t, i18n} = useTranslation();
  const {socket, connectWebSocket} = useContext(socketContext);
  const [spread, setSpread] = useState([]);
  const [quoteResult, setQuoteResult] = useState(null);
  Transfer.whyDidYouRender = true;

  function isButtonDisabled({
    mintValue, recebeValue, walletValue, pixKey, pixKeyValid,
    ValidCpfCnpj, isValid, users, isLoading, SocketMessage,
    selectedTokenRecebe, selectedToken, DEFAULTCOIN
}) {
    const isEmptyOrZero = (value) => value === '' || value === 0;

    // Verifica se os valores são vazios ou zero
    if (isEmptyOrZero(mintValue) || isEmptyOrZero(recebeValue)) {
        return true;
    }

    // Verifica se o valor da carteira está vazio
    if (walletValue === '') {
        return true;
    }

    // Verifica o estado do pixKey, pixKeyValid e ValidCpfCnpj
    if (pixKey === '' || !pixKeyValid || !ValidCpfCnpj) {
        return true;
    }

    // Verifica o estado do 2FA
    if (!isValid && users?.twoFAActivated) {
        return true;
    }

    // Verifica se há alguma condição inválida ou se está carregando
    if (isInvalid || isLoading) {
        return true;
    }

    // Verifica a condição específica da mensagem do socket
    if (isNaN(SocketMessage?.data?.baseFee) &&
        ((selectedTokenRecebe.abbreviation === DEFAULTCOIN && selectedToken.abbreviation === 'BRL') ||
        (selectedTokenRecebe.abbreviation === 'BRL' && selectedToken.abbreviation === 'USD'))) {
        return true;
    }

    // Se todas as verificações acima não forem verdadeiras, retorna false
    return false;
}
   const deparaBank = {
    "Banco A.J. RENNER - 654": "92874270",
    "Banco ABC Brasil - 246": "28195667",
    "Banco ABN AMRO - 75": "3532415",
    "Banco ALFA - 25": "3323840",
    "Banco ARBI - 213": "54403563",
    "Banco AZTECA do Brasil - 19": "9391857",
    "Banco BARCLAYS - 740": "61146577",
    "Banco BBM - 107": "15114366",
    "Banco BMFBOVESPA - 96": "997185",
    "Banco BMG - 318": "61186680",
    "Banco BNP PARIBAS Brasil - 752": "1522368",
    "Banco BOA VISTA INTERATLANTICO - 248": "33485541",
    "Banco BONSUCESSO - 218": "71027866",
    "Banco BRACCE - 65": "48795256",
    "Banco BRADESCARD - 63": "4184779",
    "Banco BRADESCO - 237": "60746948",
    "Banco BRADESCO BBI - 36": "60746948",
    "Banco BRADESCO CARTÕES - 204": "59438325",
    "Banco BRADESCO FINANCIAMENTOS - 394": "7207996",
    "Banco BTG PACTUAL - 208": "30306294",
    "Banco CACIQUE - 263": "33349358",
    "Banco CAIXA GERAL – Brasil - 473": "33466988",
    "Banco CAPITAL - 412": "15173776",
    "Banco CARGILL - 40": "3609817",
    "Banco CEDULA - 266": "33132044",
    "Banco CETELEM - 739": "558456",
    "Banco CIFRA - 233": "62421979",
    "Banco CITIBANK - 745": "33479023",
    "Banco CLÁSSICO - 241": "31597552",
    "Banco COOPERATIVO do Brasil – Bancoob - 756": "2038232",
  "Banco COOPERATIVO SICREDI - 748": "1181521",
  "Banco CREDIT AGRICOLE Brasil - 222": "75647891",
  "Banco CREDIT SUISSE (Brasil) - 505": "32062580",
  "Banco da AMAZONIA - 3": "4902979",
  "Banco da CHINA Brasil - 83": "10690848",
  "Banco DAYCOVAL - 707": "62232889",
  "Banco de LA NACION ARGENTINA - 300": "33042151",
  "Banco de LA PROVINCIA de BUENOS AIRES - 495": "44189447",
  "Banco de LA REPUBLICA ORIENTAL DEL URUGUAY - 494": "51938876",
  "Banco de TOKYO MITSUBISHI UFJ Brasil - 456": "60498557",
  "Banco DIBENS - 214": "61199881",
  "Banco do BRASIL - 1": "0",
  "Banco do ESTADO de SERGIPE - 47": "13009717",
  "Banco do ESTADO do PARA - 37": "4913711",
  "Banco do ESTADO do RIO GRANDE do SUL (BANRISUL) - 41": "92702067",
  "Banco do NORDESTE do Brasil - 4": "7237373",
  "Banco FATOR - 265": "33644196",
  "Banco FIBRA - 224": "58616418",
  "Banco FICSA - 626": "61348538",
  "Banco GERADOR - 121": "10664513",
  "Banco GUANABARA - 612": "31880826",
  "Banco INDUSTRIAL do Brasil - 604": "31895683",
  "Banco INDUSTRIAL e COMERCIAL - 320": "7450604",
  "Banco INDUSVAL - 653": "61024352",
  "Banco INTERCAP - 630": "58497702",
  "Banco INTERMEDIUM - 77": "416968",
  "Banco INVESTCRED UNIBANCO - 249": "61182408",
  "Banco ITAU BBA - 184": "17298092",
  "Banco ITAÚ HOLDING FINANCEIRA - 652": "60872504",
  "Banco J. SAFRA - 74": "3017677",
  "Banco J.P. MORGAN - 376": "33172537",
  "Banco JOHN DEERE - 217": "91884981",
  "Banco KDB do Brasil - 76": "7656500",
  "Banco KEB do Brasil - 757": "2318507",
  "Banco LUSO BRASILEIRO - 600": "59118133",
  "Banco MÁXIMA - 243": "33923798",
  "Banco MERCANTIL do BRASIL - 389": "17184037",
  "Banco MIZUHO do Brasil - 370": "61088183",
  "Banco MODAL - 746": "30723886",
  "Banco MORGAN STANLEY DEAN WITTER - 66": "2801938",
  "Banco ORIGINAL - 212": "92894922",
  "Banco ORIGINAL do Agronegócio - 79": "9516419",
  "Banco PANAMERICANO - 623": "59285411",
  "Banco PAULISTA - 611": "61820817",
  "Banco PECUNIA - 613": "60850229",
  "Banco PETRA - 94": "11758741",
  "Banco PINE - 643": "62144175",
  "Banco POTTENCIAL - 735": "253448",
  "Banco RABOBANK INTERNATIONAL Brasil - 747": "1023570",
  "Banco RANDON - 88": "11476673",
  "Banco RENDIMENTO - 633": "68900810",
  "Banco RIBEIRAO PRETO - 741": "517645",
  "Banco RODOBENS - 120": "33603457",
  "Banco SAFRA - 422": "58160789",
  "Banco SANTANDER (Brasil) - 33": "90400888",
  "Banco SEMEAR - 743": "795423",
  "Banco SOCIETE GENERALE Brasil - 366": "61533584",
  "Banco SOFISA - 637": "60889128",
  "Banco SUMITOMO MITSUI Brasileiro - 464": "60518222",
  "Banco TOPAZIO - 82": "7679404",
  "Banco TRIÂNGULO - 634": "17351180",
  "Banco VOTORANTIM - 655": "59588111",
  "Banco VR - 610": "78626983",
  "Banco WESTERN UNION do Brasil - 119": "13720915",
  "Banco WOORI BANK do Brasil - 124": "15357060",
  "BANESTES (Banco do ESTADO do ESPIRITO SANTO) - 21": "28127603",
  "BANIF – Banco INTERNACIONAL do FUNCHAL (Brasil) - 719": "33884941",
  "BANK OF AMERICA MERRILL LYNCH Banco Múltiplo - 755": "62073200",
  "BCV – Banco de Crédito e Varejo - 250": "50585090",
  "BES Investimento do Brasil – Banco de Investimento - 78": "34111187",
  "BM TRICURY - 18": "57839805",
  "BNY MELLON - 17": "42272526",
  "BPN Brasil Banco Múltiplo - 69": "61033106",
  "BRADESCO BERJ - 122": "33147315",
  "BRASIL PLURAL Banco Múltiplo - 125": "45246410",
  "BRB – Banco de Brasília - 70": "208",
  "BRICKELL Crédito, Financiamento e Investimento - 92": "12865507",
  "CAIXA ECONOMICA FEDERAL - 104": "360305",
  "CC CREDICOAMO Crédito Rural Cooperativa - 10": "81723108",
  "CC UNICRED Brasil Central - 112": "4243780",
  "CC UNICRED do Brasil - 136": "315557",
  "CC UNIPRIME NORTE do PARANA - 84": "2398976",
  "CECOOPES – Central das Cooperativas de Economia e Crédito Mútuo - 114": "5790149",
  "CITIBANK N.A. - 477": "33042953",
  "Cooperativa Central de Crédito do Estado de SP - 90": "73085573",
  "Cooperativa Central de Crédito NOROESTE Brasileiro - 97": "4632856",
  "Cooperativa Central de Crédito Urbano – CECRED - 85": "5463212",
  "Cooperativa de Crédito Rural da Região da Mogiana - 89": "62109566",
  "CREDIALIANCA Cooperativa de Crédito RURAL - 98": "78157146",
  "DEUTSCHE BANK – Banco ALEMÃO - 487": "62331228",
  "HIPERCARD Banco Múltiplo - 62": "3012230",
  "HSBC BANK Brasil – Banco Múltiplo - 399": "1701201",
  "ICBC do Brasil Banco Múltiplo - 132": "17453575",
  "ING BANK N.V. - 492": "49336860",
  "ITAÚ UNIBANCO - 341": "60701190",
  "NOVO Banco CONTINENTAL - 753": "74828799",
  "PARANA Banco - 254": "14388334",
  "SCOTIABANK Brasil Banco Múltiplo - 751": "29030467",
  "UNICRED Central RS – Central de Cooperativa de Economia e Crédito Mútuo do Estado do RS - 91": "1634601",
  "UNICRED Central Santa Catarina - 87": "543968",
  "UNIPRIME Central – Central Interestadual de Cooperativa de Crédito - 99": "3046391"
  }
  const stopPropagation = (event) => {
    event.stopPropagation();
};

  const bankOptions = Object.entries(deparaBank).map(([bankName, bankCode]) => ({
    value: bankCode,
    label: bankName
}));

// State para armazenar o banco selecionado
const [selectedBank, setSelectedBank] = useState(null);

const handleChange = (selectedOption) => {
  console.log(selectedOption)
  setSelectedBank(selectedOption);
  // Aqui você pode fazer outras ações, como atualizar um estado ou chamar um callback
  console.log(`Banco selecionado:`, selectedOption);
};
 
const [accountType, setAccountType] = useState('checking');

    // Função para lidar com a mudança de seleção do select
    const handleAccountTypeChange = (event) => {
        setAccountType(event.target.value);
    };


useEffect(() => {
  
  const fetchData = async () => {
    try {
      const response = await fetch(`${ENDPOINT}/on-chain/usdc-usdt-spread`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        // Agora você pode usar a variável 'data' para acessar os dados da resposta
        console.log(data);
        setSpread(data);
      } else if (response.status === 401) {
        // Redirecionar para a página /login se o status for 401
        navigate('/login');
      } else {
        throw new Error('Erro na requisição');
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []);

  
  
  const [users, setUsers] = useState([]);
  const [fees, setFees] = useState([]);
  const [balance, setBalance] = useState([]);
  const [balanceUsd, setBalanceUsd] = useState([]);
  const [balanceUsdt, setBalanceUsdt] = useState([]);
  const [pixKeyType, setPixKeyType] = useState(users?.kyc?.documentType === 'CPF' ? 'CPF' : 'CNPJ');
  const [pixKey, setPixKey] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [fullName, setfullName] = useState('');
  const [transferType, settransferType] = useState({
    flag: '',
    abbreviation: 'pix'
  });
  const [pixKeyValid, setPixKeyValid] = useState(false);
  const [erroreq, seterroreq] = useState(false);
  const [currencyType, setcurrencyType] = useState('');
  const [CpfCnpj, setCpfCnpj] = useState('');
  const [Token, setToken] = useState(null);
  const [SocketMessage, setSocketMessage] = useState([]);
  const [ValidCpfCnpj, setValidCpfCnpj] = useState(false);
  const [marginLeftValue, setMarginLeftValue] = useState('100px');
  const [marginLeftValueP, setMarginLeftValueP] = useState('70px');
  const [codeoneActive, SetcodeoneActive] = useState(false);
  const [codetwoActive, SetcodetwoActive] = useState(false);
  const [codetreeActive, SetcodetreeActive] = useState(false);
  const [codefourActive, SetcodefourActive] = useState(false);
  const [codefiveActive, SetcodefiveActive] = useState(false);
  const [codesixActive, SetcodesixActive] = useState(false);
  useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedFees = localStorage.getItem('feesData');
    if (loadedFees) {
      setFees(JSON.parse(loadedFees));
    }

   if (!socket || socket.readyState !== socket.OPEN) {
   connectWebSocket();
   }
 }, []);
  const isCPFValid = (strCPF) => {
    let Soma = 0;
    let Resto;
  
    if (strCPF === "00000000000") return false;
  
    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }
  
    Resto = (Soma * 10) % 11;
  
    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;
  
    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    }
  
    Resto = (Soma * 10) % 11;
  
    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  
    return true;
  };

  function validaCNPJ (cnpj) {
    var b = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]
    var c = String(cnpj).replace(/[^\d]/g, '')
    
    if(c.length !== 14)
        return false

    if(/0{14}/.test(c))
        return false

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if(c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    return true
}


  const handlePixKeyChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setPixKeyValid(false)
    const inputValue = event.target.value;
    let formattedValue = inputValue;
  
    

    setPixKey(inputValue);
  };

  const handleBranchCodeChange = (event) => {
    const inputValue = event.target.value;  
    setBranchCode(inputValue);
  };

  const handleAccountNumberChange = (event) => {
    const inputValue = event.target.value;  
    setAccountNumber(inputValue);
  };

  const handleFullNameChange = (event) => {
    const inputValue = event.target.value;  
    setfullName(inputValue);
  };



  const handleCpfCnpjChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setValidCpfCnpj(false);
    const inputValue = event.target.value;
    let formattedValue = inputValue;
  
    

    setCpfCnpj(inputValue);
  }; 

  function isValidEmailFormat(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);

  }

  
  
  const handlePixKeyFormat = () => {
    let formattedValue = pixKey;
  
    // Check for CPF format (11 digits)
    if (isCPFValid(pixKey) && /^\d{11}$/.test(pixKey)) {
      formattedValue = `${pixKey.substring(0, 3)}.${pixKey.substring(3, 6)}.${pixKey.substring(6, 9)}-${pixKey.substring(9)}`;
      
    }
  
     // Check for CNPJ format (14 digits)
  if (validaCNPJ(pixKey) && /^\d{14}$/.test(pixKey)) {
    formattedValue = `${pixKey.substring(0, 2)}.${pixKey.substring(2, 5)}.${pixKey.substring(5, 8)}/${pixKey.substring(8, 12)}-${pixKey.substring(12)}`;
    
  }

  // Check for Cell Phone format (11 or 12 digits)
  if (/^\d{11,12}$/.test(pixKey) && !isCPFValid(pixKey) && !validaCNPJ(pixKey)) {
    formattedValue = `+55 (${pixKey.substring(0, 2)}) ${pixKey.substring(2, 7)}-${pixKey.substring(7)}`;
    
  }

  if (pixKey.replace(' ','').replace(/-/g, '').length === 32) {
   setPixKeyValid(true);
   setPixKeyType('Chave-aleatoria');
   GetPixInfo(pixKey.replace(' ',''));
   console.log("chave aleatoria")
  }

  if (pixKey.replace('+55','',).replace('(','').replace(')').replace(' ').replace('-','').length === 11) {
    setPixKeyValid(true)
    setPixKeyType('Celular');
    GetPixInfo(pixKey.replace('+55','',).replace('(','').replace(')').replace(' ').replace('-',''))
   }
  
  if (isValidEmailFormat(pixKey.replace(' ',''))) {
    setPixKeyValid(true)
    setPixKeyType('Email');
    GetPixInfo(pixKey.replace(' ',''))
  }


  if (isCPFValid(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''))) {
    setPixKeyValid(true);
    setPixKeyType('CPF');
    setValidCpfCnpj(true);
    GetPixInfo(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));

  }

  if (validaCNPJ(pixKey.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))) {
    setPixKeyValid(true);
    setPixKeyType('CNPJ');
    setValidCpfCnpj(true);
    GetPixInfo(pixKey.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))
  }

    setPixKey(formattedValue);
  };



  const handleCpfCnpjFormat = () => {
    let formattedValue = CpfCnpj;
  
    // Check for CPF format (11 digits)
    if (isCPFValid(CpfCnpj) && /^\d{11}$/.test(CpfCnpj)) {
      formattedValue = `${CpfCnpj.substring(0, 3)}.${CpfCnpj.substring(3, 6)}.${CpfCnpj.substring(6, 9)}-${CpfCnpj.substring(9)}`;
      
    }
  
     // Check for CNPJ format (14 digits)
  if (validaCNPJ(CpfCnpj) && /^\d{14}$/.test(CpfCnpj)) {
    formattedValue = `${CpfCnpj.substring(0, 2)}.${CpfCnpj.substring(2, 5)}.${CpfCnpj.substring(5, 8)}/${CpfCnpj.substring(8, 12)}-${CpfCnpj.substring(12)}`;
    
  }





  if (isCPFValid(CpfCnpj.replace('.','').replace('-','').replace('.','').replace(' ',''))) {
    setValidCpfCnpj(true);
    setCpfCnpj(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));

  }

  if (validaCNPJ(CpfCnpj.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))) {
    setValidCpfCnpj(true);
    setCpfCnpj(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));
  }

    setCpfCnpj(formattedValue);
  };

  const handleWalletChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setWalletValue(event.target.value);
    
  };


  useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      const parsedUsers = JSON.parse(loadedUsers);
    setUsers(parsedUsers);
    setPixKeyType(parsedUsers.kyc.documentType === 'CPF' ? 'CPF' : 'CNPJ');
    }


    
    const fetchBalance = async () => {
    
   
      // Cria uma nova instância do web3 com o Infura
      const web3 = new Web3(new Web3.providers.HttpProvider(POLYGONURL));

      // Mapeia cada carteira para um objeto que inclui o saldo BRLA
      
          const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[137];
          const USDC_CONTRACT_ADDRESS = USDC_CONTRACT_ADDRESSES[137];
          const USDT_CONTRACT_ADDRESS = USDT_CONTRACT_ADDRESSES[137];
          const contract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
          const contractUsd = new web3.eth.Contract(USDCContractAbi, USDC_CONTRACT_ADDRESS);
          const contractUsdt = new web3.eth.Contract(USDTContractAbi, USDT_CONTRACT_ADDRESS);
          const balance = await contract.methods.balanceOf(users?.wallets?.evm).call();
          const balanceUsd = await contractUsd.methods.balanceOf(users?.wallets?.evm).call();
          const balanceUsdt = await contractUsdt.methods.balanceOf(users?.wallets?.evm).call();
          setBalance(balance)
          setBalanceUsdt(balanceUsdt);
          setBalanceUsd(balanceUsd);

      
      

      
  };

  if (users?.wallets?.evm) {
    fetchBalance();
  }



  }, [users?.wallets?.evm]);
  
  const handlePixKeyTypeChange = (type) => {
    setPixKeyType(type);
    setPixKey('')
  };

  

  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [priceusd, setPriceusd] = useState('');
  const navigate = useNavigate();



  useEffect(() => {
    // Detectar sistema operacional
    const os = window.navigator.platform;
    const userAgent = window.navigator.userAgent;
    const linuxPattern = /Linux/;
    const windowsPattern = /Win/;
    const firefoxPattern = /Firefox/;
  
    // Se o sistema operacional for Linux ou o navegador for Firefox, defina marginLeft para '30px'
    if ( firefoxPattern.test(userAgent)) {
      setMarginLeftValue('20px');
      setMarginLeftValueP('-10px');
    }

    else if (linuxPattern.test(os)) {

      setMarginLeftValue('60px');
      setMarginLeftValueP('30px');
      
    }
  
    // Se o sistema operacional for Windows, defina marginLeft para '100px'
    else {
      setMarginLeftValue('100px');
      setMarginLeftValueP('70px');
    }
  }, []);
  

 

  const {
    register,
    handleSubmit,
    setFocus,
    control, // Adicione esta linha
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const [mintValue, setMintValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [ishighValue, setIshighvalue] = useState(false);
  const [recebeValue, setRecebeValue] = useState('');
  const [showPopup, setshowPopup]= useState(false);
  const [walletValue, setWalletValue] = useState('');
  const [isInvalidrecebe, setIsInvalidRecebe] = useState(false);

  function debounce(func, delay) {
    let timeout;
    return function(...args) {
      const context = this;
      return new Promise((resolve, reject) => {
        clearTimeout(timeout);
        timeout = setTimeout(async () => {
          try {
            const result = await func.apply(context, args);
            resolve(result);
          } catch (error) {
            reject(error);
          }
        }, delay);
      });
    };
  }

  const debouncedFastQuote = useRef(debounce(async (amount, chain, inputCoin, outputCoin, fixOutput) => {
    const result = await fastQuote(amount, chain, inputCoin, outputCoin, fixOutput);
    setQuoteResult(result);
    return result;
  }, 500));


const [isMintInput, setIsMintInput] = useState(true);

const handleMintInputChange = (e) => {
    let value = e.target.value;
    setIsMintInput(true);
    setMessage('');
    if (selectedChain.abbreviation!=='Tron'&&selectedToken.abbreviation!=='USDT'&&selectedTokenRecebe.abbreviation!=='USDT') {
      setRecebeValue('');
       }
  setIshighvalue(false);
    // Remove qualquer caractere que não seja número, ponto ou vírgula
    value = value.replace(/[^\d.,]/g, '');
  
    // Adiciona o "R$" apenas para exibição no input
    const displayValue = `${value}`;
  
    // Verifica se o valor é uma string vazia
    if (value === '') {
      setMintValue(displayValue);
     
      setIsInvalid(false);
      return;
    }

    if ((parseFloat(value)>50000 && selectedToken.abbreviation==='USD' && selectedTokenRecebe.abbreviation==='USDT' ) || (parseFloat(value)>50000 && selectedToken.abbreviation==='USDT' && selectedTokenRecebe.abbreviation==='USDC' )) {
      setIshighvalue(true)
    }
  
    // Remove o "R$" para realizar a validação numérica
    const numericValue = parseFloat(value.replace('R$', '').replace(',', '.'));
  
    // Verifica se o valor é um número com até duas casas decimais
    if (/^\d*\.?\d{0,2}$/.test(numericValue)) {
      setMintValue(displayValue);
      if (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='BRL') {
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balance / Math.pow(10, 18)));
      if (displayValue<fees?.pixOutFee/100) {
        setRecebeValue(0);
      }
      else {
        setRecebeValue((parseFloat(displayValue)-parseFloat(fees?.pixOutFee)/100).toFixed(2));
      }}
      else if (selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='BRL') {
        setRecebeValue(SocketMessage?.data?.amountUsd/100);
        
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:selectedChain.abbreviation,
            coin:selectedTokenRecebe.abbreviation,
            usdToBrla: false,
            fixOutput: false,
            operation:"swap"
                        
                    }
      });
      }

      else if (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USD') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:selectedChain.abbreviation,
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: false,
            operation:"pix-to-usd"
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USDT') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:selectedChain.abbreviation,
            coin:'USDT',
            usdToBrla: true,
            fixOutput: false,
            operation:"pix-to-usd"
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='BRL') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "TesteTron",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:selectedChain.abbreviation,
            coin:'USDT',
            usdToBrla: false,
            fixOutput: false,
            operation:"swap"
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USD') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:selectedChain.abbreviation,
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: false,
            operation:"swap"
                        
                    }
      });
        
      }


      else if (selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USDT') {
        setRecebeValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:parseFloat(displayValue)*100,
            chain:selectedChain.abbreviation,
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: false,
            operation:"swap"
                        
                    }
      });
        
      }

      else if (selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='USD') {
        setRecebeValue(parseFloat(displayValue));
        setIsInvalid((numericValue) > (balanceUsd / Math.pow(10, 6)));
        
      }

      
      else if (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD') {
        setRecebeValue((parseFloat(displayValue)*parseFloat(spread.spread)).toFixed(2));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsd / Math.pow(10, 6)));
        
      }

      else if (selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USDT') {
        setRecebeValue((parseFloat(displayValue)/parseFloat(spread.spread)).toFixed(2));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsdt / Math.pow(10, 6)));
        
      }

      else if (selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL') {
        if (selectedChain.abbreviation==='GLMR') {
          debouncedFastQuote.current(parseFloat((numericValue * 100).toFixed(0)), 'Moonbeam', 'BRLA', selectedTokenRecebe.abbreviation, false);
          } else if (selectedChain.abbreviation==='CELO') {
            debouncedFastQuote.current(parseFloat((numericValue * 100).toFixed(0)), 'Celo', 'BRLA', selectedTokenRecebe.abbreviation, false);

          }
          else if (selectedChain.abbreviation==='GNOSIS') {
            debouncedFastQuote.current(parseFloat((numericValue * 100).toFixed(0)), 'Gnosis', 'BRLA', selectedTokenRecebe.abbreviation, false);

          } 
          else {
            setRecebeValue(parseFloat(displayValue));
            setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balance / Math.pow(10, 18)));
            
          }
        
      }

      else if (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USDT') {
        if (selectedChain.abbreviation==='Tron') {
        debouncedFastQuote.current(parseFloat((numericValue * 100).toFixed(0)), selectedChain.abbreviation, selectedToken.abbreviation, selectedTokenRecebe.abbreviation, false);
        } else {
          setRecebeValue(parseFloat(displayValue));
          setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsdt / Math.pow(10, 6)));
        }
      }

    }
  };
  

  useEffect(() => {
    if (quoteResult) {
      if ((selectedTokenRecebe.abbreviation === 'USDT' && selectedToken.abbreviation === 'USDT' && selectedChain.abbreviation === 'Tron')) {
        if (quoteResult.amountUsd !== undefined) {
          if (isMintInput) {
            if ((parseFloat(quoteResult.amountUsd) - parseFloat(quoteResult.gasFee)) <0 )  {
              setRecebeValue(0)


            }
            else { setRecebeValue((parseFloat(quoteResult.amountUsd) - parseFloat(quoteResult.gasFee) ).toString());
            }
          } else {
            if ((parseFloat(quoteResult.amountUsd) - parseFloat(quoteResult.gasFee)) <0 )  {
              setMintValue(0) } else {
                setMintValue((parseFloat(quoteResult.amountUsd) - parseFloat(quoteResult.gasFee) ).toString());
              }
            
          }
        }
      }

     else if ((selectedTokenRecebe.abbreviation === 'BRLA' && selectedToken.abbreviation === 'BRL' && (selectedChain.abbreviation === 'GLMR' || selectedChain.abbreviation === 'CELO' || selectedChain.abbreviation === 'GNOSIS' ))) {
        if (quoteResult.amountBrl !== undefined) {
          if (isMintInput) {
            if ((parseFloat(quoteResult.amountBrl) - parseFloat(quoteResult.gasFee)) <0 )  {
              setRecebeValue(0)


            }
            else { setRecebeValue((parseFloat(quoteResult.amountBrl) - parseFloat(quoteResult.gasFee) ).toString());
            }
          } else {
            if ((parseFloat(quoteResult.amountBrl) - parseFloat(quoteResult.gasFee)) <0 )  {
              setMintValue(0) } else {
                setMintValue((parseFloat(quoteResult.amountBrl) - parseFloat(quoteResult.gasFee) ).toString());
              }
            
          }
        }
      }
    }
  }, [quoteResult, isMintInput]);
  
  
    
  


  const handleRecebeInputChange = (e) => {
    let value = e.target.value;
    setIsMintInput(false);
    setMessage('');
    if (selectedChain.abbreviation!=='Tron'&&selectedToken.abbreviation!=='USDT'&&selectedTokenRecebe.abbreviation!=='USDT') {
      setMintValue('');
      setIshighvalue(false);
    }
    // Remove qualquer caractere que não seja número, ponto ou vírgula
    value = value.replace(/[^\d.,]/g, '');
  
    // Adiciona o "R$" apenas para exibição no input
    const displayValue = `${value}`;
  
    // Verifica se o valor é uma string vazia
    if (value === '') {
      setRecebeValue(displayValue);
      setIsInvalidRecebe(false);
      return;
    }

    if ( (parseFloat(value)>50000 && selectedToken.abbreviation==='USD' && selectedTokenRecebe.abbreviation==='USDT') || (parseFloat(value)>50000 && selectedToken.abbreviation==='USDT' && selectedTokenRecebe.abbreviation==='USDC') ) {
      setIshighvalue(true)
    }
  
    // Remove o "R$" para realizar a validação numérica
    const numericValue = parseFloat(value.replace('R$', '').replace(',', '.'));
  
    // Verifica se o valor é um número com até duas casas decimais
    if (/^\d*\.?\d{0,2}$/.test(numericValue)) {
      if ((selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='BRL')) {
        setMintValue(parseFloat(displayValue) + parseFloat(fees?.pixOutFee/100));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue+fees?.pixOutFee/100) > (balance / Math.pow(10, 18)));
      }
      else if ((selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USD')) {
        setMintValue((parseFloat(displayValue)/(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:selectedChain.abbreviation,
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: true,
            operation:"pix-to-usd"
                        
                    }
      });

      }
      else if ((selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USD')) {
        setMintValue((parseFloat(displayValue)/(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:selectedChain.abbreviation,
            coin:DEFAULTCOIN,
            usdToBrla: true,
            fixOutput: true,
            operation:"swap"
                        
                    }
      });

      }

      else if ((selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USDT')) {
        setMintValue((parseFloat(displayValue)/(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:selectedChain.abbreviation,
            coin:'USDT',
            usdToBrla: true,
            fixOutput: true,
            operation:"swap"
                        
                    }
      });

      }

      else if ((selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='BRL')) {
        setMintValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
console.log("A mensagem do socket é", SocketMessage);
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:selectedChain.abbreviation,
            coin:selectedTokenRecebe.abbreviation,
            usdToBrla: false,
            fixOutput: true,
            operation:"swap"
                        
                    }
      });
      }

      else if ((selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='BRL')) {
        setMintValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
console.log("A mensagem do socket é", SocketMessage);
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:selectedChain.abbreviation,
            coin:selectedTokenRecebe.abbreviation,
            usdToBrla: false,
            fixOutput: true,
            operation:"swap"
                        
                    }
      });
      }


      else if ((selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USDT')) {
        console.log("caiu aqui")
        setMintValue((parseFloat(displayValue)*(SocketMessage?.data?.amountBrl/SocketMessage?.data?.amountUsd)).toFixed(2));
console.log("A mensagem do socket é", SocketMessage);
        sendMessage({
          messageId: "qualquer",
          operation: "Quote",
          data: {
            amount:displayValue*100,
            chain:selectedChain.abbreviation,
            coin:"USDT",
            usdToBrla: true,
            fixOutput: true,
            operation:"pix-to-usd"
                        
                    }
      });
      }

      

      else if ((selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='USD')) {
        setMintValue(parseFloat(displayValue));
        setIsInvalid( (numericValue) > (balanceUsd / Math.pow(10, 6)));
      }

      else if ((selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL')) {
        setMintValue(parseFloat(displayValue));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balance / Math.pow(10, 18)));
      }

      else if (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD') {
        setMintValue((parseFloat(displayValue)/parseFloat(spread.spread)).toFixed(2));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsd / Math.pow(10, 6)));
        
      }

      else if (selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USDT') {
        setMintValue((parseFloat(displayValue)*parseFloat(spread.spread)).toFixed(2));
        setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsd / Math.pow(10, 6)));
        
      }

      else if ((selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USDT')) {
        if (selectedChain.abbreviation==='Tron') {
          debouncedFastQuote.current(parseFloat((numericValue * 100).toFixed(0)), selectedChain.abbreviation, selectedToken.abbreviation, selectedTokenRecebe.abbreviation, true);
          } else {
            setMintValue(parseFloat(displayValue));
            setIsInvalid(numericValue < fees?.pixOutFee/100 || (numericValue) > (balanceUsdt / Math.pow(10, 6)));
          }

      }
   
        
      }
    
     
    
      setRecebeValue(displayValue);
   
    
  };


  useEffect(() => {

    setCompleted(false);
    setMessage('');
    setRecebeValue('');
    setMintValue('');
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:100000,
        chain:selectedChain.abbreviation,
        coin:DEFAULTCOIN,
        usdToBrla: false,
        fixOutput: false,
        operation:"swap"
                    
                }
  });

  }, []);

  useEffect(() => {
    setMessage('');
    if (recebeValue === '') {
      setMintValue('');
      setIsInvalid(false)
    } 

    if (isNaN(mintValue)) {
      setIsInvalid(true)
    }
    

    if (selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='BRL') {
      setIsInvalid(true)
      if (!(isNaN(mintValue) || isNaN(recebeValue))) { 
      setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='')};
    
  
    } 

    if (selectedTokenRecebe?.abbreviation==='USDT' && selectedToken?.abbreviation==='BRL') {
      setIsInvalid(true)
      if (!(isNaN(mintValue) || isNaN(recebeValue))) { 
      setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='')};
    
  
    } 
    
    if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }

    if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USDT') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsdt / Math.pow(10, 6)) || mintValue==='')};
    }

    if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }

    if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USDT') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsdt / Math.pow(10, 6)) || mintValue==='')};
    }

   





  }, [recebeValue]);

  
  
  useEffect(() => {
    setMessage('');
    if (mintValue === '') {
     
     setRecebeValue('');
      
      
      setIsInvalid(false)
    } 
    if (isNaN(mintValue) || mintValue ==='') {
      setIsInvalid(true)
    }

    if (selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='BRL') {
      setIsInvalid(true)
      if (!(isNaN(mintValue) || isNaN(recebeValue))) { 
      setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='')};
    
  
    } 

    
    if (selectedTokenRecebe?.abbreviation==='USDT' && selectedToken?.abbreviation==='BRL') {
      setIsInvalid(true)
      if (!(isNaN(mintValue) || isNaN(recebeValue))) { 
      setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='')};
    
  
    } 
    
    if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }

    if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USDT') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsdt / Math.pow(10, 6)) || mintValue==='')};
    }

    if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }

    
    if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USDT') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsdt / Math.pow(10, 6)) || mintValue==='')};
    }

    if (selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='USD') {

      setRecebeValue(mintValue)

    }

    
    
    
  }, [mintValue]);
  

  useEffect(() => {
    if(SocketMessage?.data?.amountUsd) {
        setPriceusd(SocketMessage.data.amountBrl/SocketMessage.data.amountUsd)
        if (selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='BRL') {
          setMintValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='');
        }

        if (selectedTokenRecebe?.abbreviation==='USDT' && selectedToken?.abbreviation==='BRL') {
          setMintValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setIsInvalid((mintValue) > (balance / Math.pow(10, 18)) || mintValue==='');
        }

        if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') {
          setMintValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='');
        }
        
        if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USDT') {
          setMintValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setIsInvalid((mintValue) > (balanceUsdt / Math.pow(10, 6)) || mintValue==='');
        }

        if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USDT') {
          setMintValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setIsInvalid((mintValue) > (balanceUsdt / Math.pow(10, 6)) || mintValue==='');
        }

        if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') {
          setMintValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='');
        }

        if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') {
          setMintValue((SocketMessage.data.amountUsd/100).toFixed(2));
          setRecebeValue((SocketMessage.data.amountBrl/100).toFixed(2));
          setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='');
        }
        
    }
}, [SocketMessage]);


  const isFormValid = () => {
    return mintValue !== '' && pixKey !== '' && !isInvalid;
  };
 

  const ClickUsa = (e) => {
    e.stopPropagation(); // Impede a propagação do evento de clique
    setcurrencyType('USA');
    setPixKey('');
  }

  const ClickBrazil = (e) => {
    e.stopPropagation(); // Impede a propagação do evento de clique
    setcurrencyType('Brazil');
    setPixKey('');
  }



  const [showListaCoins, setShowListaCoins] = useState(false);
  const [showListaCoinsRecebe, setShowListaCoinsRecebe] = useState(false);
  const [showListaChains, setShowListaChains] = useState(false);
  const [showListaTransfer, setShowListaTransfer] = useState(false);
  const ListaCoinsRef = useRef();
  const ListaTransferRef = useRef();
  const ListaCoinsRecebeRef = useRef();

  const buttonRefListaCoinst = useRef(null);
  const buttonRefListaCoinsRecebet = useRef(null);
  const buttonRefListaTransfer = useRef(null);

  const ListaChainsRef = useRef();
  const buttonRefListaChain = useRef(null);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaCoins && ListaCoinsRef.current && !ListaCoinsRef.current.contains(event.target) && !buttonRefListaCoinst.current.contains(event.target)) {
      setShowListaCoins(false);
    
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaCoins]);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaCoinsRecebe && ListaCoinsRecebeRef.current && !ListaCoinsRecebeRef.current.contains(event.target) && !buttonRefListaCoinsRecebet.current.contains(event.target)) {
      setShowListaCoinsRecebe(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaCoinsRecebe]);


useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaTransfer && ListaTransferRef.current && !ListaTransferRef.current.contains(event.target) && !buttonRefListaTransfer.current.contains(event.target)) {
      setShowListaTransfer(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaTransfer]);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaChains && ListaChainsRef.current && !ListaChainsRef.current.contains(event.target) && !buttonRefListaChain.current.contains(event.target)) {
      setShowListaChains(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaChains]);


const [selectedToken, setSelectedToken] = useState({
  flag: 'currency-flag-brl',
  abbreviation: 'BRL'
});


const [selectedTokenRecebe, setSelectedTokenRecebe] = useState({
  flag: 'currency-flag-brl',
  abbreviation: 'BRL'
});

const [selectedChain, setSelectedChain] = useState({
  flag: 'currency-flag-polygon',
  abbreviation: 'Polygon'
});

const handleTokenSelect = (token) => {
  setMintValue('');
  setRecebeValue('');
  setQuoteResult('');
  setSelectedChain({
    flag: 'currency-flag-polygon',
    abbreviation: 'Polygon'
  })
  setSelectedToken(token);
  setShowListaCoins(false); // Close the dropdown after selection
  if (token.abbreviation === 'USD') {
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:100000,
        chain:selectedChain.abbreviation,
        coin:DEFAULTCOIN,
        usdToBrla: true,
        fixOutput: false,
        operation:"pix-to-usd"
                    
                }
  });
  }

  else if (token.abbreviation === 'USDT') {

    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:100000,
        chain:selectedChain.abbreviation,
        coin:'USDT',
        usdToBrla: true,
        fixOutput: false,
        operation:"pix-to-usd"
                    
                }
  });

  }
  else {
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:100000,
        chain:selectedChain.abbreviation,
        coin:DEFAULTCOIN,
        usdToBrla: false,
        fixOutput: false,
        operation:"swap"
                    
                }
  });
  }
};


const handleListaSelect = (type) => {
  setMintValue('');
  setRecebeValue('');
  setShowListaTransfer(false);
  settransferType(type);

};



const handleTokenRecebeSelect = (token) => {
  setSelectedTokenRecebe(token);
  setQuoteResult('');
  setSelectedChain({
    flag: 'currency-flag-polygon',
    abbreviation: 'Polygon'
  })
  if (token.abbreviation === 'USDT' && selectedToken.abbreviation==='BRL') {
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:100000,
        chain:selectedChain.abbreviation,
        coin:"USDT",
        usdToBrla: false,
        fixOutput: false,
        operation:"swap"
                    
                }
  });
  }
  else if (token.abbreviation === 'USDC' && selectedToken.abbreviation==='BRL') {
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:100000,
        chain:selectedChain.abbreviation,
        coin:"USDC",
        usdToBrla: false,
        fixOutput: false,
        operation:"swap"
                    
                }
  });
  }
  setShowListaCoinsRecebe(false); // Close the dropdown after selection
};


const handleChainSelect = (chain) => {
  setSelectedChain(chain);
  setQuoteResult('');
  console.log("chain: ", chain)

  
  if (selectedToken.abbreviation === 'BRL' && selectedTokenRecebe.abbreviation==='USDT') {
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:100000,
        chain:chain.abbreviation,
        coin:"USDT",
        usdToBrla: false,
        fixOutput: false,
        operation:"swap"
                    
                }
  });
  }
  
 
  setShowListaChains(false); // Close the dropdown after selection
};

const currencyFlagClass = `currency-flag ${selectedToken.flag}`;
const currencyFlagRecebeClass = `currency-flag ${selectedTokenRecebe.flag}`;
const currencyFlagPIXClass = `currency-flag currency-flag-pix`;
const currencyFlagPolygonClass = selectedChain.abbreviation === 'Polygon' ?  `currency-flag currency-flag-polygon` : selectedChain.abbreviation === 'XRP' ? `currency-flag currency-flag-xrp` : selectedChain.abbreviation === 'Tron' ? `currency-flag currency-flag-tron` :  selectedChain.abbreviation === 'CELO' ? `currency-flag currency-flag-celo` :  selectedChain.abbreviation === 'GNOSIS' ? `currency-flag currency-flag-gnosis` : `currency-flag currency-flag-moonbeam`   ;
const sendMessage = (messageObject) => {
  if (socket && socket.readyState === socket.OPEN) {
    console.log("Socket está recebendo :",messageObject)
      socket.send(JSON.stringify(messageObject));
  } else {
      console.error("socket is not open.");
  }
}

useEffect(() => {
  if (socket) {
    socket.onmessage = (event) => {
 
    const  data = JSON.parse(event.data)

      console.log("retorno socket", data);
      if (data?.success && data?.data?.quoteId) {
        setCompleted(true);
        setIsLoading(false);
      }
      else {
        setIsLoading(false);
        
      }
      if (!data?.success && data?.error && ((selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='BRL') || (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') || (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USDT') || (selectedTokenRecebe?.abbreviation==='USDT' && selectedToken?.abbreviation==='BRL') || (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USDT') || (selectedTokenRecebe?.abbreviation==='USDC' && selectedToken?.abbreviation==='BRL') || (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') ) ) {
        setIsLoading(false);
    
      }

      if (data?.message === "user is already consuming the endpoint") {
        setMessage({
          text: "user is already consuming the endpoint",
          color: "red",
        });

      }
      setSocketMessage(data);
      setMessage({text: data.error, color:'red'});
     
    };
  }

  return () => {
    if (socket) {
      socket.onmessage = null;
    }
  };
}, [socket]);



const onSubmit = async (data) => {
  setIsLoading(true); // Definir como loading quando a requisição começar
  if (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='BRL' ) {

    const cleanedPixKey = pixKeyType === 'CPF' || pixKeyType === 'CNPJ' || pixKeyType === 'Celular' ? pixKey.replace(/[-.()]/g, '').replace('R$ ', '').replace('+55', '').replace('/', '').replace(/ /g, '') : pixKey;
   console.log("pix key", pixKey)
   console.log("cleanedPixKey", cleanedPixKey)
    setIsLoading(true); // Definir como loading quando a requisição começar
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    const response = await fetch(`${ENDPOINT}/pay-out?otp=${data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "MAINTENANCE-TOKEN":MANTEINENCETOKEN
      },
      credentials:'include',
      body: transferType.abbreviation === 'pix' ?  JSON.stringify({
        pixKey: cleanedPixKey,
        notifyEmail: true,
        amount: parseFloat((parseFloat(mintValue)*100).toFixed(0)),
        taxId: pixKeyType==='CPF' || pixKeyType==='CNPJ' ? cleanedPixKey : CpfCnpj

      }) : JSON.stringify({
        name: fullName,
        notifyEmail: true,
        taxId: CpfCnpj,
        ispb: selectedBank.value,
        branchCode: branchCode,
        accountNumber: accountNumber,
        accountType: accountType,
        amount: parseFloat((parseFloat(mintValue)*100).toFixed(0)),

      }),
    });

   
    setIsLoading(false); // Definir como não loading quando a requisição terminar
  
    if (response.status === 200 || response.status === 201) {
      
      setCompleted(true)
      // Não reabilite o botão
    } else {
      let responseData = {};
      if (response.headers.get('content-type').includes('application/json')) {
        responseData = await response.json();
      }
      // Trate erros aqui, caso necessário
     setMessage({text: responseData.error, color: 'red'})

  }

  if (response.status === 401) {
    setMessage({text: 'Erro: Refaça o login', color: 'red'});
    navigate('/login');

    // Reabilite o botão
    setIsLoading(false);
  }
  setIsLoading(false);
}

if (((selectedTokenRecebe.abbreviation===DEFAULTCOIN || selectedTokenRecebe.abbreviation==='USDT' ) && selectedToken.abbreviation==='BRL' ))   {
  setIsLoading(true); // Definir como loading quando a requisição começar
 
  

  sendMessage({
    messageId: "qualquer",
    operation: "PlaceSwapOrder",
    data: {
      quoteId:SocketMessage?.data?.quoteId,
      notifyEmail:true,
      receiverWallet:walletValue,
      otp: data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6
                  
              }
});





}

if ( (selectedTokenRecebe.abbreviation==='BRL' && (selectedToken.abbreviation==='USD' || selectedToken.abbreviation==='USDT') )) {
  const cleanedPixKey = pixKeyType === 'CPF' || pixKeyType === 'CNPJ' || pixKeyType === 'Celular' ? pixKey.replace(/[-.()]/g, '').replace('R$ ', '').replace('+55', '').replace('/', '').replace(/ /g, '') : pixKey;
  
  setIsLoading(true)

  sendMessage({
    messageId: "qualquer",
    operation: "PlaceUsdToPixOrder",
    data: {
      quoteId:SocketMessage?.data?.quoteId,
      notifyEmail:true,
      pixKey:cleanedPixKey,
      taxId: (pixKeyType === 'CPF' || pixKeyType === 'CNPJ') 
      ? pixKey 
      : CpfCnpj.replace(/[.\-\/]/g, ''),
      otp: data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6
                  
              }
});


}

if ( (selectedTokenRecebe.abbreviation==='BRLA' && (selectedToken.abbreviation==='USD' || selectedToken.abbreviation==='USDT' ) ) ) {
  setIsLoading(true)

  sendMessage({
    messageId: "qualquer",
    operation: "PlaceSwapOrder",
    data: {
      quoteId:SocketMessage?.data?.quoteId,
      notifyEmail:true,
      pixKey:pixKey,
      receiverWallet:walletValue,
      otp: data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6
                  
              }
});


}

if ( (selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='USDT' )) {
  setIsLoading(true)

  sendMessage({
    messageId: "qualquer",
    operation: "PlaceSwapOrder",
    data: {
      quoteId:SocketMessage?.data?.quoteId,
      notifyEmail:true,
      pixKey:pixKey,
      receiverWallet:walletValue
                  
              }
});


}




if ((selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='USD') || (selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL') || (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD') || (selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USDT') || (selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USDT') ) {

  setIsLoading(true); // Definir como loading quando a requisição começar
  setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior


  if (selectedChain.abbreviation==='Tron'&&selectedToken.abbreviation==='USDT'&&selectedTokenRecebe.abbreviation==='USDT') {
   let amount = 0
   let fixOutput = false
   if (isMintInput) {
    amount = parseFloat((parseFloat(mintValue)*100).toFixed(0))
    fixOutput = false
   } else {
    amount = parseFloat((parseFloat(recebeValue)*100).toFixed(0))
    fixOutput = true
   }
   const quoteResult = await debouncedFastQuote.current(amount, selectedChain.abbreviation, selectedToken.abbreviation, selectedTokenRecebe.abbreviation, fixOutput);
   const otp = data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6
   const result = await GenerateSwap(quoteResult?.token, walletValue, otp)
   if (!result.error) {
    setCompleted(true)
   } else {
    setMessage({text: result.error, color: 'red'}) 
   }
  }
else if ((selectedChain.abbreviation!='GLMR')&&(selectedChain.abbreviation!='CELO')&&(selectedChain.abbreviation!='GNOSIS')) {
  const response = await fetch(`${ENDPOINT}/on-chain/transfer?otp=${data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "MAINTENANCE-TOKEN":MANTEINENCETOKEN,
    },
    credentials:'include',
    body: JSON.stringify({
      to: walletValue,
      chain: selectedChain.abbreviation,
      inputCoin:  selectedToken.abbreviation==='USD' ? 'USDC' : selectedToken.abbreviation==='BRL' ? 'BRLA' : selectedToken.abbreviation,
      outputCoin:selectedTokenRecebe.abbreviation,
      value: parseFloat((parseFloat(recebeValue)*100).toFixed(0))
    }),
  });

  if (response.status === 200 || response.status === 201) {
    
    setCompleted(true)
    // Não reabilite o botão
  } else {
    let responseData = {};
    if (response.headers.get('content-type').includes('application/json')) {
      responseData = await response.json();
    }
    // Trate erros aqui, caso necessário
   setMessage({text: responseData.error, color: 'red'})

}

if (response.status === 401) {
  setMessage({text: 'Erro: Refaça o login', color: 'red'});
  navigate('/login');

  // Reabilite o botão
  setIsLoading(false);
}
} else if (selectedChain.abbreviation==='GLMR') {


  let amount = 0
  let fixOutput = false
  if (isMintInput) {
   amount = parseFloat((parseFloat(mintValue)*100).toFixed(0))
   fixOutput = false
  } else {
   amount = parseFloat((parseFloat(recebeValue)*100).toFixed(0))
   fixOutput = true
  }
  const quoteResult = await debouncedFastQuote.current(amount, 'Moonbeam', 'BRLA', selectedTokenRecebe.abbreviation, fixOutput);
  const otp = data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6
  const result = await GenerateSwap(quoteResult?.token, walletValue, otp)
  if (!result.error) {
   setCompleted(true)
  } else {
   setMessage({text: result.error, color: 'red'}) 
  }
}

else if (selectedChain.abbreviation==='CELO') {

  let amount = 0
  let fixOutput = false
  if (isMintInput) {
   amount = parseFloat((parseFloat(mintValue)*100).toFixed(0))
   fixOutput = false
  } else {
   amount = parseFloat((parseFloat(recebeValue)*100).toFixed(0))
   fixOutput = true
  }
  const quoteResult = await debouncedFastQuote.current(amount, 'Celo', 'BRLA', selectedTokenRecebe.abbreviation, fixOutput);
  const otp = data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6
  const result = await GenerateSwap(quoteResult?.token, walletValue, otp)
  if (!result.error) {
   setCompleted(true)
  } else {
   setMessage({text: result.error, color: 'red'}) 
  }
}

else if (selectedChain.abbreviation==='GNOSIS') {

  let amount = 0
  let fixOutput = false
  if (isMintInput) {
   amount = parseFloat((parseFloat(mintValue)*100).toFixed(0))
   fixOutput = false
  } else {
   amount = parseFloat((parseFloat(recebeValue)*100).toFixed(0))
   fixOutput = true
  }
  const quoteResult = await debouncedFastQuote.current(amount, 'Gnosis', 'BRLA', selectedTokenRecebe.abbreviation, fixOutput);
  const otp = data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6
  const result = await GenerateSwap(quoteResult?.token, walletValue, otp)
  if (!result.error) {
   setCompleted(true)
  } else {
   setMessage({text: result.error, color: 'red'}) 
  }
}
  
  setIsLoading(false); // Definir como não loading quando a requisição terminar

}



}

const handleCoinSelected = (selectedCoin) => {
  if (selectedCoin?.abbreviation === 'USD') {
  }
  // Aqui, você pode adicionar outras condições para diferentes moedas, se necessário.
};

const getClass = () => {
 
   if (selectedTokenRecebe.abbreviation === 'BRL' && selectedToken.abbreviation === 'BRL' && !pixKeyValid) {
    return 'brl-brl';
    
  } else if (selectedTokenRecebe.abbreviation === DEFAULTCOIN && selectedToken.abbreviation === 'USD') {
    return 'brl-brl'
  }
  else if (selectedTokenRecebe.abbreviation === 'BRL' && selectedToken.abbreviation === 'USD' && pixKeyValid) {
    return 'brl-usd-pix'
      }
   else {
    return 'brl-usd';
  }

}
const [pixInfo, setPixInfo] = useState(null);

async function GetPixInfo(pixKeyinput) {
  console.log("Teste se ta duplicado 1")
  const response = await fetch(`${ENDPOINT}/pay-out/pix-info?pixKey=${pixKeyinput}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "MAINTENANCE-TOKEN":MANTEINENCETOKEN,
    },
    credentials:'include',
  });
  setIsLoading(false); // Definir como não loading quando a requisição terminar

  if (response.status === 200 || response.status === 201) {
    const responseData = await response.json();
    setPixInfo(responseData);

    
    // Não reabilite o botão
  } else {
    let responseData = {};
    if (response.headers.get('content-type').includes('application/json')) {
      responseData = await response.json();
    }
    // Trate erros aqui, caso necessário
   setMessage({text: responseData.error, color: 'red'})

}
}

function toTitleCase(str) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

function handleMax() {
  if (selectedToken.abbreviation === 'BRL' && selectedTokenRecebe.abbreviation === DEFAULTCOIN) {
    let value = balance / Math.pow(10, 18);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:selectedChain.abbreviation,
        coin:selectedTokenRecebe.abbreviation,
        usdToBrla: false,
        fixOutput: false,
        operation:"swap"
                    
                }
  });
  } 
  else if ((selectedToken.abbreviation === 'USD' && selectedTokenRecebe.abbreviation === 'BRL')) {
    let value = balanceUsd / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    console.log("PAULADA")
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:selectedChain.abbreviation,
        coin:'USDC',
        usdToBrla: true,
        fixOutput: false,
        operation:"pix-to-usd"
                    
                }
  });
  }


  else if ((selectedToken.abbreviation === 'USDT' && selectedTokenRecebe.abbreviation === 'BRL')) {
    let value = balanceUsdt / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:selectedChain.abbreviation,
        coin:'USDT',
        usdToBrla: true,
        fixOutput: false,
        operation:"pix-to-usd"
                    
                }
  });
  }

  else if ((selectedToken.abbreviation === 'BRL' && selectedTokenRecebe.abbreviation === 'USDT')) {
    let value = balance / Math.pow(10, 18);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:selectedChain.abbreviation,
        coin:'USDT',
        usdToBrla: false,
        fixOutput: false,
        operation:"swap"
                    
                }
  });
  }

  else if ((selectedToken.abbreviation === 'USD' && selectedTokenRecebe.abbreviation === 'BRLA')) {
    let value = balanceUsd / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:selectedChain.abbreviation,
        coin:'USDC',
        usdToBrla: true,
        fixOutput: false,
        operation:"swap"
                    
                }
  });
  }

  
  else if ((selectedToken.abbreviation === 'USDT' && selectedTokenRecebe.abbreviation === 'BRLA')) {
    let value = balanceUsdt / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setMintValue(finalValue);
    
    sendMessage({
      messageId: "qualquer",
      operation: "Quote",
      data: {
        amount:parseFloat((parseFloat(finalValue)*100).toFixed(0)),
        chain:selectedChain.abbreviation,
        coin:'USDT',
        usdToBrla: true,
        fixOutput: false,
        operation:"swap"
                    
                }
  });
  }

  else if ((selectedToken.abbreviation === 'USD' && selectedTokenRecebe.abbreviation === DEFAULTCOIN)) {
    let value = balanceUsd / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    setRecebeValue(finalValue)
    

  }

  else if ((selectedToken.abbreviation === 'USDT' && selectedTokenRecebe.abbreviation === DEFAULTCOIN)) {
    let value = (balanceUsdt / Math.pow(10, 6))/parseFloat(spread.spread);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    setRecebeValue(finalValue)
    

  }

  else if ((selectedToken.abbreviation === 'USDT' && selectedTokenRecebe.abbreviation === 'USDT')) {
    let value = balanceUsdt / Math.pow(10, 6);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    setRecebeValue(finalValue)
    

  }


  else if ((selectedToken.abbreviation === 'USD' && selectedTokenRecebe.abbreviation === 'USDT')) {
    let value = (balanceUsd / Math.pow(10, 6))*parseFloat(spread.spread);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    setRecebeValue(finalValue)
    

  }


  else if ((selectedToken.abbreviation === 'BRL' && selectedTokenRecebe.abbreviation === 'BRL')) {
    let value = balance / Math.pow(10, 18);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    setRecebeValue(finalValue-fees?.pixOutFee/100)
    

  }

  else if ((selectedToken.abbreviation === 'BRL' && selectedTokenRecebe.abbreviation === 'BRLA')) {
    let value = balance / Math.pow(10, 18);
    let roundedValue = Math.floor(value * 100) / 100;
    let finalValue = parseFloat(roundedValue.toFixed(2));
    setIsInvalid(false)
    setMintValue(finalValue);
    setRecebeValue(finalValue)
    

  }
}


async function fastQuote(amount, chain, inputCoin, outputCoin, fixOutput) {
  
  try {
    const resFastQuote = await fetch(`${ENDPOINT}/fast-quote?operation=swap&amount=${amount}&chain=${chain}&inputCoin=${inputCoin}&outputCoin=${outputCoin}&fixOutput=${fixOutput}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        "MAINTENANCE-TOKEN": MANTEINENCETOKEN
      },
    });
  
    if (resFastQuote.status === 401) {
      navigate('/login'); 
      return;
    }
    const data = await resFastQuote.json(); 
    return data;
  } catch (error) {
    if (selectedChain.abbreviation==='Tron'&&selectedToken.abbreviation==='USDT'&&selectedTokenRecebe.abbreviation==='USDT') {
      setMintValue('')
      setRecebeValue('')
    }
      console.error('Error:', error.response?.data || error.message);
      throw error;
  }

  
}
  
  
  
  async function GenerateSwap(token, receiverAddress, otp) {
    try {
    const resFastQuote = await fetch(`${ENDPOINT}/swap?otp=${otp}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        "MAINTENANCE-TOKEN": MANTEINENCETOKEN
      },
      body: JSON.stringify({
        token: token,
        receiverAddress: receiverAddress,
      }),
    });
  
    if (resFastQuote.status === 401) {
      navigate('/login'); 
      return;
    }
    const data = await resFastQuote.json(); 
    return data;
  } catch (error) {
    if (selectedChain.abbreviation==='Tron'&&selectedToken.abbreviation==='USDT'&&selectedTokenRecebe.abbreviation==='USDT') {
      setMintValue('')
      setRecebeValue('')
      setMessage({text: error.response?.data || error.message, color: 'red'})
    }
      console.error('Error:', error.response?.data || error.message);
      throw error;
  }
  }



  return (
    <>
    {showPopup  && (
 <div className="wallet-popup-overlay" style={{zIndex:5000}}>
 <div className="wallet-popup" style={{position: 'relative', zIndex:20000}}>
   


<p className="warning" style={{color:'black'}}>You are already connected. Please make sure to close any connections you have with us</p>


                 

 </div>
</div>
)}


    <form style={{zIndex:3000}} onSubmit={handleSubmit(onSubmit)}>
         <div style={{zIndex:700}} className="np-theme-personal" ref={ref}>
   
   

       
          <section className={`tw-flow__main ${getClass()}`} style={{ position: "absolute",width:'500px',  background:'white', padding:'20px', border:'1px solid white', borderRadius:'15px' }}>
           
          {!completed ? (
      
               
                    <div  className="row mx-auto">
                      <div  className="">
                        <div className="tabs">
                  
                          <div
                            className="tabs__panel-container"
                            style={{ overflow: "visible" }}
                          >
                            <div
                              className="tabs__slider"
                              style={{
                                width: "100%",
                                transform: "translateX(0px)"
                              }}
                            >
                              <div style={{ width: 32, display: "none" }} />
                              <div
                                className="tabs__panel"
                                role="tabpanel"
                                id="calculator-tabs-panel-0"
                                aria-labelledby="calculator-tabs-tab-0"
                                style={{ width: "100%", display: "block" }}
                              >
                                <div
                                  data-tracking-id="calculator"
                                  data-testid="calculator"
                                  className="tw-claculator"
                                >
                                  <div className="" style={{width:'450px'}}>
                                    <label htmlFor="tw-calculator-source">
                                    {t('voce_envia_exatamente')}
                                    </label>
                                    <button style={{marginLeft:"10px", fontSize:"12px", color:'#00dc84'}} className='limpar-botao' type='button' onClick={handleMax}>Max</button>
                                    <div  className="tw-money-input form-control" style={{display:'flex', height:'50px', border:'1px solid black', borderRadius:'15px'}}>
                                      <div style={{display:'flex'}}>
                                      <input
                                        className="input-transfer"
                                        id="tw-calculator-source"
                                        inputMode="decimal"
                                        placeholder="1000.00"
                                        autoComplete="off"
                                        style={{border:'none'}}
                                        onChange={handleMintInputChange}
                                        value={
                                          isNaN(mintValue) ? "Carregando..." : mintValue
                                        }

                                        
                                        
                                      />
                                      <div  className="input-group-btn amount-currency-select-btn">
                                        <div style={{width:'100px', marginLeft:marginLeftValue}} className="np-select btn-block btn-group">
                                         <button
                                         style={{minHeight:'70px', height:'70px'}}
                                        
                                         
                                         
  ref={buttonRefListaCoinst}
  className={`btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy`}
  id="tw-calculator-source-select"
  
  role="combobox"
  aria-controls="tw-calculator-source-select-listbox"
  aria-expanded="false"
  aria-autocomplete="none"
  type="button"
  onClick={() => {
    setMintValue('');
    setRecebeValue('');
    setShowListaCoins(!showListaCoins);
  }}
>
                                    
                                            <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                              <div  className="d-flex flex-column justify-content-center">
                                              <i style={{marginLeft: selectedToken.abbreviation === 'USDT' ? '-25px' : '-25px'}} className={currencyFlagClass} />
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                  <span style={{fontSize:'18px', marginRight:'25px', marginLeft:'-7px'}} className="np-text-title-subsection tw-money-input__text">
                                                  {selectedToken.abbreviation==='USD' ? 'USDC' : selectedToken.abbreviation}
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                              style={{marginBottom:'40px'}}
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                                className={`${showListaCoins ? 'rotate-icon' : ''}`}
                                                
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                            
                                          </button>
                                          
                                        </div>
                                        
                                      </div>
                                      </div>
                                      {showListaCoins && <ListaCoins ref={ListaCoinsRef} onTokenSelect={handleTokenSelect} top='80px' left='200px'  />}
 {showListaTransfer && <ListaTransfer ref={ListaTransferRef} onListSelect={handleListaSelect} top='80px' left='200px'  />}

                                      <div
  style={{
    position: 'absolute',
    top: selectedToken.abbreviation==='BRL' && selectedTokenRecebe.abbreviation===DEFAULTCOIN ? '250px' : selectedTokenRecebe.abbreviation !== selectedToken.abbreviation &&
      !(selectedTokenRecebe.abbreviation === DEFAULTCOIN && selectedToken.abbreviation === 'USD')
      ? '250px'   
      : '180px',
    left: '200px',
    zIndex:2000
  }}
>
  {showListaCoinsRecebe && <ListaCoinsRecebe ref={ListaCoinsRecebeRef} onTokenRecebeSelect={handleTokenRecebeSelect} />}
  {showListaChains && <ListaChains ref={ListaChainsRef} onChainSelect={handleChainSelect} token = {selectedToken.abbreviation} tokenRecebe = {selectedTokenRecebe.abbreviation} />}
</div>




                                      
                                    </div>
                                    
                                  </div>
                                  {(selectedTokenRecebe.abbreviation !== selectedToken.abbreviation ) && !(selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='USD' ) && !(selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL' ) && !(selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD') && !(selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USDT')  ? (
                                  <ul className="tw-calculator-breakdown " style={{marginBottom:'-20px'}}>
                                    < li style={{width:'450px', marginTop:'-15px'}}>
                                      <span className="tw-calculator-breakdown__icon">
                                        <span>
                                          <span>–</span>
                                        </span>
                                      </span>
                                      <div  className="tw-calculator-breakdown__content">
                                        <span style={{ fontSize:"14px"}}  className=" tw-calculator-breakdown-item__value">
                                        {
  isNaN(SocketMessage?.data?.baseFee) ? (
    t('carregando')+"..."
  ) : (
    
    parseFloat(SocketMessage?.data?.baseFee).toFixed(2) +   (selectedTokenRecebe.abbreviation ==='USDT' || selectedTokenRecebe.abbreviation ==='USDC' || selectedTokenRecebe.abbreviation ==='USD' ? ' USD' : " BRL")
  )
}
                                        </span>
                                        <span className="tw-calculator-breakdown-item__right tw-calculator-breakdown-item__label">
                                          <span data-tracking-id="calculator-payment-select">
                                            <div className="np-select btn-group">
                                              <button
                                                className="btn btn-sm np-btn np-btn-sm np-dropdown-toggle np-text-body-large"
                                                id="np-select-443252"
                                                role="combobox"
                                                aria-controls="np-select-443252-listbox"
                                                aria-expanded="false"
                                                aria-autocomplete="none"
                                                type="button"
                                              >
                                                <span style={{ fontSize:"14px"}} className="np-text-body-large text-ellipsis">
                                                {t('tarifa_total_de_custo')}
                                                </span>
                                                <span
                                                  className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                                  aria-hidden="true"
                                                  role="presentation"
                                                  data-testid="chevron-up-icon"
                                                >
                                                                                             </span>
                                              </button>
                                            </div>
                                          </span>
                                        </span>
                                      </div>
                                    </li>
                                
             
                                    < li style={{width:'450px', marginTop:'-10px'}}>
                                      <span className="tw-calculator-breakdown__icon">
                                        <span>
                                          <span>÷</span>
                                        </span>
                                      </span>
                                      <div  className="tw-calculator-breakdown__content">
                                        <span  className="tw-calculator-breakdown-item__left tw-calculator-breakdown-item__value">
                                          <a
                                            role="button"
                                            className="btn-unstyled"
                                            tabIndex={0}
                                          >
                                            <span style={{ fontSize:"14px"}} className="tw-calculator-breakdown-rate__value">
                                            {
  isNaN(SocketMessage?.data?.basePrice) ? (
    t('carregando')+"..."
  ) : (
    parseFloat(SocketMessage?.data?.basePrice).toFixed(4)
  )
}
                                            </span>
                                          </a>
                                        </span>
                                        <span className="tw-calculator-breakdown-item__right tw-calculator-breakdown-item__label">
                                          <span className="np-popover">
                                            <span className="d-inline-block">
                                              <button
                                                className="btn btn-md np-btn np-btn-md btn-accent btn-priority-3 btn-unstyled p-a-0 tw-calculator-rate-text"
                                                type="button"
                                                style={{
                                                  height: "auto",
                                                  verticalAlign: "bottom"
                                                }}
                                              >
                                                <span style={{ fontSize:"14px"}} data-tracking-id="calculator-fixed-rate-tooltip">
                                                {t('taxa_de_cambio_comercial')}
                                                </span>{" "}
                                                <span
                                                  style={{ whiteSpace: "nowrap" }}
                                                >
                                                 
                                                </span>
                                              </button>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </li>
                                  </ul> ) :(<div></div>)}
                                  <div className="" style={{width:'450px', marginTop:'0px'}}>
                                    <label htmlFor="tw-calculator-target">
                                    {t('beneficiario_recebe')}{" "}
                                    </label>
                                    <div  className="tw-money-input form-control" style={{display:'flex', height:'50px', border:'1px solid black', borderRadius:'15px'}}>
                                      <input
                                        className="input-transfer"
                                        id="tw-calculator-target"
                                        inputMode="decimal"
                                        placeholder={
                                          (selectedToken.abbreviation === 'USD' && selectedTokenRecebe.abbreviation === DEFAULTCOIN) 
                                            ? '1000.00'
                                            : (selectedTokenRecebe.abbreviation === DEFAULTCOIN  && selectedToken.abbreviation === 'BRL' )
                                                ? (isNaN(SocketMessage?.data?.amountUsd) ? t('carregando')+"..." : (SocketMessage?.data?.amountUsd / 100).toFixed(2))
                                                : (selectedTokenRecebe.abbreviation === 'BRL'  && selectedToken.abbreviation === 'USDT' )
                                                ? (isNaN(SocketMessage?.data?.amountBrl) ? t('carregando')+"..." : (SocketMessage?.data?.amountBrl / 100).toFixed(2))
                                                : '999.25'
                                        }
                                        
                                      
                                        autoComplete="off"
                                        onChange={handleRecebeInputChange}
                                        value={isNaN(recebeValue) ? "Carregando..." : recebeValue}

                         
                                        
                                      />
                                      <div className="input-group-btn amount-currency-select-btn">
                                      <div style={{width:'100px', marginLeft:marginLeftValue}} className="np-select btn-block btn-group">
                                          <button
                                          style={{minHeight:'70px', height:'70px'}}
                                           ref={buttonRefListaCoinsRecebet}
                                            className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                            id="tw-calculator-target-select"
                                            role="combobox"
                                            aria-controls="tw-calculator-target-select-listbox"
                                            aria-expanded="false"
                                            aria-autocomplete="none"
                                            type="button"
                                            onClick={() => {
                                              setMintValue('');
                                              setRecebeValue('');
                                                setShowListaCoinsRecebe(!showListaCoinsRecebe);
                                
                                              }}
                                          >
                                            <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                              <div className="d-flex flex-column justify-content-center">
                                              <i style={{marginLeft:selectedTokenRecebe.abbreviation === 'USDT' || selectedTokenRecebe.abbreviation === 'BRLA' || selectedTokenRecebe.abbreviation === 'USDC' ? '-30px' : '-5px'}} className={currencyFlagRecebeClass} />
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                <span style={{fontSize:'18px', marginRight:'25px',
                                                marginLeft:  selectedTokenRecebe.abbreviation === 'USDT' || selectedTokenRecebe.abbreviation === 'BRLA'   ? '-10px' :  selectedTokenRecebe.abbreviation === 'USDC' ? -'15px' : '-7px',}} className="np-text-title-subsection tw-money-input__text">
                                                  {selectedTokenRecebe.abbreviation}
                                                  </span>
                                                </span>
                                         
                                              </div>
                                              
                                            </div>
                                         
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                              style={{
                                                marginBottom: '40px',
                                                marginLeft: selectedTokenRecebe.abbreviation === DEFAULTCOIN || selectedTokenRecebe.abbreviation === 'BRLA' ? '3px' : '0',
                                              }}
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {(selectedTokenRecebe.abbreviation === 'BRL' ) ? (

                                    <>
                          { transferType.abbreviation==='pix' ?
                                  <div style={{marginBottom:"0px"}} className="">
                                    <label htmlFor="tw-calculator-target">
                                    {t('chave_pix')}{" "}
                                    </label>
                                    <div  className="tw-money-input form-control" style={{display:'flex', height:'50px', border:'1px solid black', borderRadius:'15px', width:"450px"}}>
                                      <input
                                        className="input-transfer"
                                        id="tw-calculator-target"
                                        inputMode="text"
                                        placeholder={t('digite_sua_chave_pix')}
                                        autoComplete="off"
                                        onChange={handlePixKeyChange}
                                        onBlur={handlePixKeyFormat}
                                        value={pixKey}
                                        style={{width:"300px"}}
                                        
                                        
                                      />
                                      <div className="input-group-btn amount-currency-select-btn">
                                      <div style={{width:'100px', marginLeft:'16px'}} className="np-select btn-block btn-group">
                                          <button
                                           style={{minHeight:'70px', height:'70px'}}
                                            className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                            id="tw-calculator-target-select"
                                            role="combobox"
                                            aria-controls="tw-calculator-target-select-listbox"
                                            aria-expanded="false"
                                            aria-autocomplete="none"
                                            type="button"
                                            ref={buttonRefListaTransfer}
                                            onClick={() => {
                                              setMintValue('');
                                              setRecebeValue('');
                                                setShowListaTransfer(!showListaTransfer);
                                            
                                
                                              }}
                                            
                                            
                                          >
                                            <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                              <div className="d-flex flex-column justify-content-center">
                                                {transferType.abbreviation ==='pix' ?
                                              <i style={{marginLeft:'-5px'}} className={currencyFlagPIXClass} /> : <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              version="1.1"
                                              width={25}
                                              height={25}
                                              viewBox="0 0 256 256"
                                              xmlSpace="preserve"
                                              
                                            >
                                              <defs></defs>
                                              <g
                                                style={{
                                                  stroke: "none",
                                                  strokeWidth: 0,
                                                  strokeDasharray: "none",
                                                  strokeLinecap: "butt",
                                                  strokeLinejoin: "miter",
                                                  strokeMiterlimit: 10,
                                                  fill: "none",
                                                  fillRule: "nonzero",
                                                  opacity: 1
                                                }}
                                                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                                              >
                                                <path
                                                  d="M 84.668 38.004 v -6.27 H 90 V 20 L 45 3.034 L 0 20 v 11.734 h 5.332 v 6.27 h 4.818 v 30.892 H 5.332 v 6.271 H 0 v 11.8 h 90 v -11.8 h -5.332 v -6.271 H 79.85 V 38.004 H 84.668 z M 81.668 35.004 H 66.332 v -3.27 h 15.336 V 35.004 z M 63.332 68.896 v 6.271 h -7.664 v -6.271 H 50.85 V 38.004 h 4.818 v -6.27 h 7.664 v 6.27 h 4.818 v 30.892 H 63.332 z M 26.668 38.004 v -6.27 h 7.664 v 6.27 h 4.818 v 30.892 h -4.818 v 6.271 h -7.664 v -6.271 H 21.85 V 38.004 H 26.668 z M 42.15 68.896 V 38.004 h 5.7 v 30.892 H 42.15 z M 37.332 35.004 v -3.27 h 15.336 v 3.27 H 37.332 z M 37.332 71.896 h 15.336 v 3.271 H 37.332 V 71.896 z M 3 22.075 L 45 6.24 l 42 15.835 v 6.659 H 3 V 22.075 z M 8.332 31.734 h 15.336 v 3.27 H 8.332 V 31.734 z M 13.15 38.004 h 5.7 v 30.892 h -5.7 V 38.004 z M 8.332 71.896 h 15.336 v 3.271 H 8.332 V 71.896 z M 87 83.966 H 3 v -5.8 h 84 V 83.966 z M 81.668 75.166 H 66.332 v -3.271 h 15.336 V 75.166 z M 76.85 68.896 H 71.15 V 38.004 h 5.699 V 68.896 z"
                                                  style={{
                                                    stroke: "none",
                                                    strokeWidth: 1,
                                                    strokeDasharray: "none",
                                                    strokeLinecap: "butt",
                                                    strokeLinejoin: "miter",
                                                    strokeMiterlimit: 10,
                                                    fill: "rgb(0,0,0)",
                                                    fillRule: "nonzero",
                                                    opacity: 1
                                                  }}
                                                  transform=" matrix(1 0 0 1 0 0) "
                                                  strokeLinecap="round"
                                                />
                                              </g>
                                            </svg>}
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                <span style={{fontSize:'18px', marginRight:"25px"}} className="np-text-title-subsection tw-money-input__text">
                                                  PIX
                                                  </span>
                                                </span>
                                         
                                              </div>
                                              
                                            </div>
                                         
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                              style={{marginBottom:'40px'}}
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>


                                    {users?.twoFAActivated && (pixKeyType==='CPF' || pixKeyType==='CNPJ') &&
                            <>
                                  <label  htmlFor="tw-calculator-target">
                                    2FA{" "}
                                  </label>
                  <div className="verify-input-container">
                    <div className="code-input-container">
                    <div className={`code-section${codeoneActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_2');
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                          }}
                          onFocus={() => {
                            SetcodeoneActive(true);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_1', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codetwoActive ? ' active' : ''}`}>

                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_3');
                            SetcodetreeActive(true);
                            SetcodetwoActive(false);
                          }}
                          onFocus={() => {
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_2', { required: true })}
                        />

                      </div>
                      <div className={`code-section${codetreeActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_4');
                            SetcodefourActive(true);
                            SetcodetreeActive(false);
                          }}
                          onFocus={() => {
                            SetcodetreeActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_3', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefourActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_5');
                            SetcodefiveActive(true);
                            SetcodefourActive(false);
                          }}
                          onFocus={() => {
                            SetcodefourActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_4', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefiveActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_6');
                            SetcodesixActive(true);
                            SetcodefiveActive(false);
                          }}
                          onFocus={() => {
                            SetcodefiveActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_5', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codesixActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onFocus={() => {
                            SetcodesixActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                          }}
                          {...register('code_6', { required: true })}
                        />
                      </div>
                    </div>
                  </div></>}

                                  </div> : 
                                  <div >

<div style={{ display: 'flex' }}>
    <div style={{ marginBottom: "0px" }}>
        <label htmlFor="tw-calculator-target">
            {t('agencia_bancaria')}{" "}
        </label>
        <div className="tw-money-input form-control" style={{ display: 'flex', height: '50px', border: '1px solid black', borderRadius: '15px', width: "180px" }}>
            <input
                className="input-transfer"
                id="tw-calculator-target"
                inputMode="text"
                placeholder={t('1234')}
                autoComplete="off"
                onChange={handleBranchCodeChange}
                //onBlur={handlePixKeyFormat}
                value={branchCode}
                style={{ width: "150px" }}
            />
        </div>
    </div>

    <div style={{ marginBottom: "0px" }}>
        <label htmlFor="tw-calculator-target">
            {t('conta_bancaria')}{" "}
        </label>
        <div className="tw-money-input form-control" style={{ display: 'flex', height: '50px', border: '1px solid black', borderRadius: '15px', width: "180px", marginLeft:'8px' }}>
            <input
                className="input-transfer"
                id="tw-calculator-target"
                inputMode="text"
                placeholder={t('12387-1')}
                autoComplete="off"
                onChange={handleAccountNumberChange}
                //onBlur={handlePixKeyFormat}
                value={accountNumber}
                style={{ width: "150px" }}
            />

            










<div className="input-group-btn amount-currency-select-btn">
                                      <div style={{width:'100px'}} className="np-select btn-block btn-group">
                                          <button
                                           style={{minHeight:'70px', height:'70px'}}
                                            className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                            id="tw-calculator-target-select"
                                            role="combobox"
                                            aria-controls="tw-calculator-target-select-listbox"
                                            aria-expanded="false"
                                            aria-autocomplete="none"
                                            type="button"
                                            ref={buttonRefListaTransfer}
                                            onClick={() => {
                                              setMintValue('');
                                              setRecebeValue('');
                                                setShowListaTransfer(!showListaTransfer);
                                            
                                
                                              }}
                                            
                                            
                                          >
                                            <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                              <div className="d-flex flex-column justify-content-center">
                                                {transferType.abbreviation ==='pix' ?
                                              <i style={{marginLeft:'-5px'}} className={currencyFlagPIXClass} /> : <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              version="1.1"
                                              width={25}
                                              height={25}
                                              viewBox="0 0 256 256"
                                              xmlSpace="preserve"
                                              
                                            >
                                              <defs></defs>
                                              <g
                                                style={{
                                                  stroke: "none",
                                                  strokeWidth: 0,
                                                  strokeDasharray: "none",
                                                  strokeLinecap: "butt",
                                                  strokeLinejoin: "miter",
                                                  strokeMiterlimit: 10,
                                                  fill: "none",
                                                  fillRule: "nonzero",
                                                  opacity: 1
                                                }}
                                                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                                              >
                                                <path
                                                  d="M 84.668 38.004 v -6.27 H 90 V 20 L 45 3.034 L 0 20 v 11.734 h 5.332 v 6.27 h 4.818 v 30.892 H 5.332 v 6.271 H 0 v 11.8 h 90 v -11.8 h -5.332 v -6.271 H 79.85 V 38.004 H 84.668 z M 81.668 35.004 H 66.332 v -3.27 h 15.336 V 35.004 z M 63.332 68.896 v 6.271 h -7.664 v -6.271 H 50.85 V 38.004 h 4.818 v -6.27 h 7.664 v 6.27 h 4.818 v 30.892 H 63.332 z M 26.668 38.004 v -6.27 h 7.664 v 6.27 h 4.818 v 30.892 h -4.818 v 6.271 h -7.664 v -6.271 H 21.85 V 38.004 H 26.668 z M 42.15 68.896 V 38.004 h 5.7 v 30.892 H 42.15 z M 37.332 35.004 v -3.27 h 15.336 v 3.27 H 37.332 z M 37.332 71.896 h 15.336 v 3.271 H 37.332 V 71.896 z M 3 22.075 L 45 6.24 l 42 15.835 v 6.659 H 3 V 22.075 z M 8.332 31.734 h 15.336 v 3.27 H 8.332 V 31.734 z M 13.15 38.004 h 5.7 v 30.892 h -5.7 V 38.004 z M 8.332 71.896 h 15.336 v 3.271 H 8.332 V 71.896 z M 87 83.966 H 3 v -5.8 h 84 V 83.966 z M 81.668 75.166 H 66.332 v -3.271 h 15.336 V 75.166 z M 76.85 68.896 H 71.15 V 38.004 h 5.699 V 68.896 z"
                                                  style={{
                                                    stroke: "none",
                                                    strokeWidth: 1,
                                                    strokeDasharray: "none",
                                                    strokeLinecap: "butt",
                                                    strokeLinejoin: "miter",
                                                    strokeMiterlimit: 10,
                                                    fill: "rgb(0,0,0)",
                                                    fillRule: "nonzero",
                                                    opacity: 1
                                                  }}
                                                  transform=" matrix(1 0 0 1 0 0) "
                                                  strokeLinecap="round"
                                                />
                                              </g>
                                            </svg>}
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                <span style={{fontSize:'18px', marginRight:"25px"}} className="np-text-title-subsection tw-money-input__text">
                                                  PIX
                                                  </span>
                                                </span>
                                         
                                              </div>
                                              
                                            </div>
                                         
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                              style={{marginBottom:'40px'}}
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>














        </div>
    </div>
</div>


<div style={{ marginBottom: "0px" }}>
        <label htmlFor="tw-calculator-target">
            {t('banco')}{" "}
        </label>
        <div>
        <Select
            id="account-type-selector"
            className="account-type-selector"
            value={selectedBank}
            onChange={handleChange}
             onMouseDown={stopPropagation}
            options={bankOptions}
            placeholder="Selecione um banco..."
            isSearchable={true}
            styles={{ 
                control: (base) => ({
                    ...base,
                    height: '50px',
                    border: '1px solid black',
                    borderRadius: '15px',
                    width: '450px'
                })
            }}
            onClick={stopPropagation}
        />
        </div>
    </div>

<div style={{ marginBottom: "0px" }}>
        <label htmlFor="tw-calculator-target">
            {t('tipo_de_conta')}{" "}
        </label>
        <div>
    <select
            id="account-type-selector"
            className="account-type-selector"
            onChange={handleAccountTypeChange}
            value={accountType}
            style={{ 
                height: '50px', 
                border: '1px solid black', 
                borderRadius: '15px', 
                width: "450px",
                padding: '10px',
                backgroundColor: 'white',
                color: 'black',
                fontSize: '16px'
            }}
        >
            <option value="checking">{t('Conta corrente')}</option>
            <option value="savings">{t('Conta poupança')}</option>
        </select>
        </div>
    </div>
    <div style={{ marginBottom: "0px" }}>
        <label htmlFor="tw-calculator-target">
            {t('nome_do_beneficiario')}{" "}
        </label>
        <div className="tw-money-input form-control" style={{ display: 'flex', height: '50px', border: '1px solid black', borderRadius: '15px', width: "450px" }}>
            <input
                className="input-transfer"
                id="tw-calculator-target"
                inputMode="text"
                placeholder={t('Texto')}
                autoComplete="off"
                onChange={handleFullNameChange}
                //onBlur={handlePixKeyFormat}
                value={fullName}
                style={{ width: "300px" }}
            />
        </div>
    </div>


       <div style={{ marginBottom: "0px" }}>
        <label htmlFor="tw-calculator-target">
            {t('cpf_do_beneficiario')}{" "}
        </label>
        <div className="tw-money-input form-control" style={{ display: 'flex', height: '50px', border: '1px solid black', borderRadius: '15px', width: "450px" }}>
            <input
                className="input-transfer"
                id="tw-calculator-target"
                inputMode="text"
                placeholder='Digite o CPF ou CNPJ do beneficiário'
                autoComplete="off"
                onChange={handleCpfCnpjChange}
                onBlur={handleCpfCnpjFormat}
                value={CpfCnpj}
                style={{ width: "300px" }}
            />
        </div>
    </div>

                                 
                                    {users?.twoFAActivated && (pixKeyType==='CPF' || pixKeyType==='CNPJ') &&
                            <>
                                  <label  htmlFor="tw-calculator-target">
                                    2FA{" "}
                                  </label>
                  <div className="verify-input-container">
                    <div className="code-input-container">
                    <div className={`code-section${codeoneActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_2');
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                          }}
                          onFocus={() => {
                            SetcodeoneActive(true);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_1', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codetwoActive ? ' active' : ''}`}>

                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_3');
                            SetcodetreeActive(true);
                            SetcodetwoActive(false);
                          }}
                          onFocus={() => {
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_2', { required: true })}
                        />

                      </div>
                      <div className={`code-section${codetreeActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_4');
                            SetcodefourActive(true);
                            SetcodetreeActive(false);
                          }}
                          onFocus={() => {
                            SetcodetreeActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_3', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefourActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_5');
                            SetcodefiveActive(true);
                            SetcodefourActive(false);
                          }}
                          onFocus={() => {
                            SetcodefourActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_4', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefiveActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_6');
                            SetcodesixActive(true);
                            SetcodefiveActive(false);
                          }}
                          onFocus={() => {
                            SetcodefiveActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_5', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codesixActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onFocus={() => {
                            SetcodesixActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                          }}
                          {...register('code_6', { required: true })}
                        />
                      </div>
                    </div>
                  </div></>}

                                  </div>}
                                  
                                  { !(pixKeyType==='CPF' || pixKeyType==='CNPJ') ? <div style={{marginBottom:"20px"}} className="">
                                  <label htmlFor="tw-calculator-target">
                                  {t('cpf_do_beneficiario')}{" "}
                                  </label>
                                  <div  className="tw-money-input form-control" style={{display:'flex', height:'50px', border:'1px solid black', borderRadius:'15px', width:"450px"}}>
                                    <input
                                      className="input-transfer"
                                      id="tw-calculator-target"
                                      inputMode="text"
                                      placeholder="Digite o CPF ou CNPJ"
                                      autoComplete="off"
                                      onChange={handleCpfCnpjChange}
                                      onBlur={handleCpfCnpjFormat}
                                      value={CpfCnpj}
                                      style={{width:"300px"}}
                                      
                                      
                                    />
                                    <div className="input-group-btn amount-currency-select-btn">
                                    <div style={{width:'100px', marginLeft:'16px'}} className="np-select btn-block btn-group">
                                        <button
                                         style={{minHeight:'70px', height:'70px'}}
                                          className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                          id="tw-calculator-target-select"
                                          role="combobox"
                                          aria-controls="tw-calculator-target-select-listbox"
                                          aria-expanded="false"
                                          aria-autocomplete="none"
                                          type="button"
                                          
                                          
                                        >
                                          <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                            <div className="d-flex flex-column justify-content-center">
                                            <i style={{marginLeft:'-5px'}} className={currencyFlagPIXClass} />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                              <span className="np-text-body-large text-ellipsis">
                                              <span style={{fontSize:'18px', marginRight:"25px"}} className="np-text-title-subsection tw-money-input__text">
                                                PIX
                                                </span>
                                              </span>
                                       
                                            </div>
                                            
                                          </div>
                                       
                                          <span
                                            className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                            aria-hidden="true"
                                            role="presentation"
                                            data-testid="chevron-up-icon"
                                            style={{marginBottom:'40px'}}
                                          >
                                            <svg
                                              width={16}
                                              height={16}
                                              fill="currentColor"
                                              focusable="false"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                            </svg>
                                          </span>
                                        </button>



                




                                      </div>




                                    </div>




                                  </div>
                                 


                          {users?.twoFAActivated &&
                            <>
                                  <label  htmlFor="tw-calculator-target">
                                    2FA{" "}
                                  </label>
                  <div className="verify-input-container">
                    <div className="code-input-container">
                    <div className={`code-section${codeoneActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_2');
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                          }}
                          onFocus={() => {
                            SetcodeoneActive(true);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_1', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codetwoActive ? ' active' : ''}`}>

                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_3');
                            SetcodetreeActive(true);
                            SetcodetwoActive(false);
                          }}
                          onFocus={() => {
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_2', { required: true })}
                        />

                      </div>
                      <div className={`code-section${codetreeActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_4');
                            SetcodefourActive(true);
                            SetcodetreeActive(false);
                          }}
                          onFocus={() => {
                            SetcodetreeActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_3', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefourActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_5');
                            SetcodefiveActive(true);
                            SetcodefourActive(false);
                          }}
                          onFocus={() => {
                            SetcodefourActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_4', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefiveActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_6');
                            SetcodesixActive(true);
                            SetcodefiveActive(false);
                          }}
                          onFocus={() => {
                            SetcodefiveActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_5', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codesixActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onFocus={() => {
                            SetcodesixActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                          }}
                          {...register('code_6', { required: true })}
                        />
                      </div>
                    </div>
                  </div></>}

                                </div> : <div></div>}
                                </>
                                  
                                  ) : (


                                  
<div className="" style={{width:'450px'}}>
                                    <label htmlFor="tw-calculator-target">
                                    {t('wallet_address')}{" "}
                                    </label>
                                    <div  className="tw-money-input form-control" style={{display:'flex', height:'50px', border:'1px solid black', borderRadius:'15px'}}>
                                      <input
                                         className="input-transfer"
                                        id="tw-calculator-target"
                                        inputMode="decimal"
                                        placeholder="0xc37...3887"
                                        autoComplete="off"
                                        onChange={handleWalletChange}
                                        value={walletValue}
                                       
                                      />
                                      <div className="input-group-btn amount-currency-select-btn">
                                      <div style={{width:'140px', marginLeft:marginLeftValueP}} className="np-select btn-block btn-group">
                                          <button
                                      
                                         style={{minHeight:'70px', height:'70px'}}
                                            className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                            id="tw-calculator-target-select"
                                            role="combobox"
                                            aria-controls="tw-calculator-target-select-listbox"
                                            aria-expanded="false"
                                            aria-autocomplete="none"
                                            type="button"  
                                            ref={buttonRefListaChain}
                                            onClick={() => {
                                              if (selectedTokenRecebe.abbreviation === 'USDT' && selectedToken.abbreviation === 'BRL' || true) {
                                                console.log("Teste XRP");
                                                setMintValue('');
                                                setRecebeValue('');
                                                setShowListaChains(!showListaChains);
                                              } else {
                                                console.log("Nao permitido trocar a chain");
                                              }
                                            }}
                                            
                                          
                                            
                                          >
                                            <div style={{justifyContent:'center', alignContent:'center', verticalAlign:'center', marginBottom:'40px'}}  className="d-flex np-option-content">
                                              <div className="d-flex flex-column justify-content-center">
                                              <i style={{marginLeft:'-5px'}} className={currencyFlagPolygonClass} />
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                <span style={{fontSize:'18px', marginRight:'25px'}} className="np-text-title-subsection tw-money-input__text">
                                                  {selectedChain.abbreviation}
                                                  </span>
                                                </span>
                                         
                                              </div>
                                              
                                            </div>
                                         
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                              style={{marginBottom:'40px'}}
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>



                                      
                                    </div>

                                    {users?.twoFAActivated &&
                            <>
                                  <label  htmlFor="tw-calculator-target">
                                    2FA{" "}
                                  </label>
                  <div className="verify-input-container">
                    <div className="code-input-container">
                    <div className={`code-section${codeoneActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_2');
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                          }}
                          onFocus={() => {
                            SetcodeoneActive(true);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_1', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codetwoActive ? ' active' : ''}`}>

                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_3');
                            SetcodetreeActive(true);
                            SetcodetwoActive(false);
                          }}
                          onFocus={() => {
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_2', { required: true })}
                        />

                      </div>
                      <div className={`code-section${codetreeActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_4');
                            SetcodefourActive(true);
                            SetcodetreeActive(false);
                          }}
                          onFocus={() => {
                            SetcodetreeActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_3', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefourActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_5');
                            SetcodefiveActive(true);
                            SetcodefourActive(false);
                          }}
                          onFocus={() => {
                            SetcodefourActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_4', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefiveActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_6');
                            SetcodesixActive(true);
                            SetcodefiveActive(false);
                          }}
                          onFocus={() => {
                            SetcodefiveActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_5', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codesixActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onFocus={() => {
                            SetcodesixActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                          }}
                          {...register('code_6', { required: true })}
                        />
                      </div>
                    </div>
                  </div></>}


                                  </div>) }

                                  {selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD' && <strong >1 USD = {spread?.spread} USDT</strong>}
                                  {(selectedTokenRecebe.abbreviation !== selectedToken.abbreviation ) && !(selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='USD' ) && !(selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL' ) && !(selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD' )  ? (


                                  <div style={{fontSize:"14px", marginTop:'-9px'}} className="tw-calculator-effective-rate">
                                    
                               
                                    <strong>1 {selectedTokenRecebe.abbreviation} = {isNaN(SocketMessage?.data?.amountBrl) || isNaN(SocketMessage?.data?.amountUsd)
  ? "Carregando... "
  : (SocketMessage?.data?.amountBrl / SocketMessage?.data?.amountUsd).toFixed(4)
+" BRL"}
 </strong>{" "}




                                    <span className="np-popover">
                                      <span className="d-inline-block">
                                        <button
                                          className="btn btn-md np-btn np-btn-md btn-accent btn-priority-3 btn-unstyled p-a-0"
                                          type="button"
                                          style={{
                                            height: "auto",
                                            verticalAlign: "bottom",
                                            fontSize:'14px'
                                          }}
                                        >
                                          {t('cambio_efetivo')} (VET)
                                        </button>
                                      </span>
                                    </span>
                                  </div> ) :(<div></div>)}

                                  { (selectedTokenRecebe.abbreviation==='BRL' && pixKey!=='' && pixKeyValid) ? (
                                    <>

                                  <hr className="" style={{marginTop:'10px'}} />

                                  <div className="" style={{marginTop:'-25px'}}>
                                  <strong className="tw-review-details-card__title">
                                    <small className="ng-binding">
                                      {" "}
                                      {t('dados_do_beneficiario')}{" "}
                                    </small>
                                  </strong>
                               
                                </div>
                               
                                <p
                                  ng-repeat="detail in $ctrl.contact.display.details track by $index"
                                  className="tw-review-element  ng-scope"
                                  style={{}}
                                >
                                  <small style={{fontSize:'14px'}} className="tw-review-element__name ng-binding">
                                  {t('titular')}
                                  </small>
                                  <span style={{fontSize:'14px', marginTop:"-2px"}} className="tw-review-element__value text-primary ng-binding">
                                  {toTitleCase(pixInfo?.name || '')}
                                  </span>
                                </p>
                            
                               
                                {/* end ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                               
                                {/* end ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                                <p
                                  className="m-b-0 tw-review-element ng-hide"
                                  ng-show="$ctrl.deliveryMethod === 'LINK'"
                                  style={{}}
                                >
                                  <small style={{fontSize:'14px'}} className="tw-review-element__name ng-binding">
                                  {t('banco')}
                                  </small>
                                  <span style={{fontSize:'14px', marginTop:"-2px"}} className="tw-review-element__value text-primary ng-binding">
                                  {toTitleCase(pixInfo?.bankName || '')}
                                  </span>
                                </p>
                                </>) : (
                                  <div></div>
                                )}
                                
                                  <div className="m-t-3 tw-calculator-notices"  >
                                    <div
                                      role="alert"
                                      className="alert d-flex alert-neutral"
                                      style={{height:'20px', marginTop:"-20px"}}
                                    >
                                      <div
                                        className="alert__content d-flex flex-grow-1 desktop"
                                        data-testid="desktop"
                                      >
                                        <span
                                          data-testid="status-icon"
                                          className="status-circle status-circle-lg neutral"
                                          style={{display:'flex',width:'30px', height:'30px', alignItems:'center', justifyContent:'center', alignContent:'center', verticalAlign:"center", marginTop:'-15px'}}
                                        >
                                          <span
                                            className="tw-icon tw-icon-info status-icon light"
                                            aria-hidden="true"
                                            role="presentation"
                                            data-testid="info-icon"
                                            
                                          >
                                            <svg
                                              width={16}
                                              height={16}
                                              fill="currentColor"
                                              focusable="false"
                                              viewBox="0 0 24 24"
                                              
                                            >
                                              <path d="M11.143 9.857h1.714v9.857h-1.714z" />
                                              <circle cx={12} cy={6} r="1.714" />
                                            </svg>
                                          </span>
                                        </span>


                                        <div style={{height:'10px', fontSize:'14px', lineHeight:"6px"}} className="alert__message">
                                          <span  className="">
                                            <span >
                                            {t('termos')}
                                            </span>


                                            <a
                                            href="https://brla.digital/terms-and-services-pt.pdf"
                                            target="_blank"
                                            className="np-link  d-inline-flex m-t-1"
                                            rel="noreferrer"
                                            style={{fontSize:'14px'}}
                                          >
                                            {t('saiba_mais')}
                                            <span
                                              className="tw-icon tw-icon-navigate-away "
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="navigate-away-icon"
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                                style={{width:'10px', height:"10px"}}
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M12.857 1.287h8.571a.86.86 0 0 1 .857.857v8.572h-1.714V4.2l-9.428 9.429-1.2-1.2 9.428-9.429h-6.514V1.287ZM3.429 20.998H18v-6h1.714v6.857a.86.86 0 0 1-.857.858H2.572a.86.86 0 0 1-.858-.858V4.713a.86.86 0 0 1 .857-.858h6V5.57H3.43v15.428Z"
                                                />
                                              </svg>
                                            </span>
                                          </a>

                                          </span>
                                
                                        </div>
                                       
                                      </div>
                                    </div>
                                  </div>
                                  {isInvalid && <p style={{display:'flex', color: 'red', fontSize:'12px', textAlign:'center', justifyContent:'center', alignItems:"center" }}>{t('valor_maior')} {fees?.pixOutFee/100} {t('saldo_disponivel')}</p>}
                                  {ishighValue && <p style={{display:'flex', color: 'red', fontSize:'12px', textAlign:'center', justifyContent:'center', alignItems:"center" }}>{t('valor_menor')}</p>}
 <p style={{ color: 'red', fontSize:'12px', textAlign:'center' }}>{message.text}</p>

                                  <div className="d-flex m-t-2">
                       
                                    <button
                                      className="btn btn-md np-btn np-btn-md btn-block np-btn-block btn-positive btn-priority-1 m-t-0"
                                      data-testid="continue-button"
                                      type="submit"
                                    //  disabled={((mintValue==='' || recebeValue==='' || mintValue===0 || recebeValue===0 || walletValue==='' ) && (mintValue==='' || recebeValue==='' || mintValue===0 || recebeValue===0  || pixKey===''  || !pixKeyValid || !ValidCpfCnpj || (!isValid && users?.twoFAActivated ))) || isInvalid || isLoading  || (isNaN(SocketMessage?.data?.baseFee) && ((selectedTokenRecebe.abbreviation===DEFAULTCOIN && selectedToken.abbreviation==='BRL') || (selectedTokenRecebe.abbreviation==='BRL' && selectedToken.abbreviation==='USD')       ) ) ||    (!isValid && users?.twoFAActivated   ) }
                                    >
                                      {isLoading ? t('carregando') + "..." : t('confirm_transfer')}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div style={{ width: 32, display: "none" }} />
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>) : (
                      <div style={{display:"flex", justifyContent:"center", paddingTop:"80px", paddingBottom:"100px"}} className="row mx-auto">
                      
                     
                  
                         
                         
                             
                             
                               
                                 
  <img style={{width:'100px', height:'100px'}} src={Checked} />
  
  <p style={{marginTop:"20px", marginBottom:'10px'}}>{t('complete')}</p>
</div>






                    )}
                 
               
            
          </section>
        </div>
     
 
        </form>    
    </>
  );
});
export default Transfer
