export const deparaBank = {
    "Banco A.J. RENNER - 654": "92874270",
    "Banco ABC Brasil - 246": "28195667",
    "Banco ABN AMRO - 75": "3532415",
    "Banco ALFA - 25": "3323840",
    "Banco ARBI - 213": "54403563",
    "Banco AZTECA do Brasil - 19": "9391857",
    "Banco BARCLAYS - 740": "61146577",
    "Banco BBM - 107": "15114366",
    "Banco BMFBOVESPA - 96": "997185",
    "Banco BMG - 318": "61186680",
    "Banco BNP PARIBAS Brasil - 752": "1522368",
    "Banco BOA VISTA INTERATLANTICO - 248": "33485541",
    "Banco BONSUCESSO - 218": "71027866",
    "Banco BRACCE - 65": "48795256",
    "Banco BRADESCARD - 63": "4184779",
    "Banco BRADESCO - 237": "60746948",
    "Banco BRADESCO BBI - 36": "60746948",
    "Banco BRADESCO CARTÕES - 204": "59438325",
    "Banco BRADESCO FINANCIAMENTOS - 394": "7207996",
    "Banco BTG PACTUAL - 208": "30306294",
    "Banco CACIQUE - 263": "33349358",
    "Banco CAIXA GERAL – Brasil - 473": "33466988",
    "Banco CAPITAL - 412": "15173776",
    "Banco CARGILL - 40": "3609817",
    "Banco CEDULA - 266": "33132044",
    "Banco CETELEM - 739": "558456",
    "Banco CIFRA - 233": "62421979",
    "Banco CITIBANK - 745": "33479023",
    "Banco CLÁSSICO - 241": "31597552",
    "Banco COOPERATIVO do Brasil – Bancoob - 756": "2038232",
  "Banco COOPERATIVO SICREDI - 748": "1181521",
  "Banco CREDIT AGRICOLE Brasil - 222": "75647891",
  "Banco CREDIT SUISSE (Brasil) - 505": "32062580",
  "Banco da AMAZONIA - 3": "4902979",
  "Banco da CHINA Brasil - 83": "10690848",
  "Banco DAYCOVAL - 707": "62232889",
  "Banco de LA NACION ARGENTINA - 300": "33042151",
  "Banco de LA PROVINCIA de BUENOS AIRES - 495": "44189447",
  "Banco de LA REPUBLICA ORIENTAL DEL URUGUAY - 494": "51938876",
  "Banco de TOKYO MITSUBISHI UFJ Brasil - 456": "60498557",
  "Banco DIBENS - 214": "61199881",
  "Banco do BRASIL - 1": "0",
  "Banco do ESTADO de SERGIPE - 47": "13009717",
  "Banco do ESTADO do PARA - 37": "4913711",
  "Banco do ESTADO do RIO GRANDE do SUL (BANRISUL) - 41": "92702067",
  "Banco do NORDESTE do Brasil - 4": "7237373",
  "Banco FATOR - 265": "33644196",
  "Banco FIBRA - 224": "58616418",
  "Banco FICSA - 626": "61348538",
  "Banco GERADOR - 121": "10664513",
  "Banco GUANABARA - 612": "31880826",
  "Banco INDUSTRIAL do Brasil - 604": "31895683",
  "Banco INDUSTRIAL e COMERCIAL - 320": "7450604",
  "Banco INDUSVAL - 653": "61024352",
  "Banco INTERCAP - 630": "58497702",
  "Banco INTERMEDIUM - 77": "416968",
  "Banco INVESTCRED UNIBANCO - 249": "61182408",
  "Banco ITAU BBA - 184": "17298092",
  "Banco ITAÚ HOLDING FINANCEIRA - 652": "60872504",
  "Banco J. SAFRA - 74": "3017677",
  "Banco J.P. MORGAN - 376": "33172537",
  "Banco JOHN DEERE - 217": "91884981",
  "Banco KDB do Brasil - 76": "7656500",
  "Banco KEB do Brasil - 757": "2318507",
  "Banco LUSO BRASILEIRO - 600": "59118133",
  "Banco MÁXIMA - 243": "33923798",
  "Banco MERCANTIL do BRASIL - 389": "17184037",
  "Banco MIZUHO do Brasil - 370": "61088183",
  "Banco MODAL - 746": "30723886",
  "Banco MORGAN STANLEY DEAN WITTER - 66": "2801938",
  "Banco ORIGINAL - 212": "92894922",
  "Banco ORIGINAL do Agronegócio - 79": "9516419",
  "Banco PANAMERICANO - 623": "59285411",
  "Banco PAULISTA - 611": "61820817",
  "Banco PECUNIA - 613": "60850229",
  "Banco PETRA - 94": "11758741",
  "Banco PINE - 643": "62144175",
  "Banco POTTENCIAL - 735": "253448",
  "Banco RABOBANK INTERNATIONAL Brasil - 747": "1023570",
  "Banco RANDON - 88": "11476673",
  "Banco RENDIMENTO - 633": "68900810",
  "Banco RIBEIRAO PRETO - 741": "517645",
  "Banco RODOBENS - 120": "33603457",
  "Banco SAFRA - 422": "58160789",
  "Banco SANTANDER (Brasil) - 33": "90400888",
  "Banco SEMEAR - 743": "795423",
  "Banco SOCIETE GENERALE Brasil - 366": "61533584",
  "Banco SOFISA - 637": "60889128",
  "Banco SUMITOMO MITSUI Brasileiro - 464": "60518222",
  "Banco TOPAZIO - 82": "7679404",
  "Banco TRIÂNGULO - 634": "17351180",
  "Banco VOTORANTIM - 655": "59588111",
  "Banco VR - 610": "78626983",
  "Banco WESTERN UNION do Brasil - 119": "13720915",
  "Banco WOORI BANK do Brasil - 124": "15357060",
  "BANESTES (Banco do ESTADO do ESPIRITO SANTO) - 21": "28127603",
  "BANIF – Banco INTERNACIONAL do FUNCHAL (Brasil) - 719": "33884941",
  "BANK OF AMERICA MERRILL LYNCH Banco Múltiplo - 755": "62073200",
  "BCV – Banco de Crédito e Varejo - 250": "50585090",
  "BES Investimento do Brasil – Banco de Investimento - 78": "34111187",
  "BM TRICURY - 18": "57839805",
  "BNY MELLON - 17": "42272526",
  "BPN Brasil Banco Múltiplo - 69": "61033106",
  "BRADESCO BERJ - 122": "33147315",
  "BRASIL PLURAL Banco Múltiplo - 125": "45246410",
  "BRB – Banco de Brasília - 70": "208",
  "BRICKELL Crédito, Financiamento e Investimento - 92": "12865507",
  "CAIXA ECONOMICA FEDERAL - 104": "360305",
  "CC CREDICOAMO Crédito Rural Cooperativa - 10": "81723108",
  "CC UNICRED Brasil Central - 112": "4243780",
  "CC UNICRED do Brasil - 136": "315557",
  "CC UNIPRIME NORTE do PARANA - 84": "2398976",
  "CECOOPES – Central das Cooperativas de Economia e Crédito Mútuo - 114": "5790149",
  "CITIBANK N.A. - 477": "33042953",
  "Cooperativa Central de Crédito do Estado de SP - 90": "73085573",
  "Cooperativa Central de Crédito NOROESTE Brasileiro - 97": "4632856",
  "Cooperativa Central de Crédito Urbano – CECRED - 85": "5463212",
  "Cooperativa de Crédito Rural da Região da Mogiana - 89": "62109566",
  "CREDIALIANCA Cooperativa de Crédito RURAL - 98": "78157146",
  "DEUTSCHE BANK – Banco ALEMÃO - 487": "62331228",
  "HIPERCARD Banco Múltiplo - 62": "3012230",
  "HSBC BANK Brasil – Banco Múltiplo - 399": "1701201",
  "ICBC do Brasil Banco Múltiplo - 132": "17453575",
  "ING BANK N.V. - 492": "49336860",
  "ITAÚ UNIBANCO - 341": "60701190",
  "NOVO Banco CONTINENTAL - 753": "74828799",
  "PARANA Banco - 254": "14388334",
  "SCOTIABANK Brasil Banco Múltiplo - 751": "29030467",
  "UNICRED Central RS – Central de Cooperativa de Economia e Crédito Mútuo do Estado do RS - 91": "1634601",
  "UNICRED Central Santa Catarina - 87": "543968",
  "UNIPRIME Central – Central Interestadual de Cooperativa de Crédito - 99": "3046391"
  }